import React from 'react';

const PageNotFound = (props) => {
  const handleReturnToDashboard = (l) => {
    window.location.reload(); // Hard refresh the page
    window.location.href = l; // Redirect to the index page
  };

  return (
    <div className="error-page" style={{ marginTop: '50px', minHeight: '500px' }}>
      <h2 className="headline text-warning"> 404</h2>

      <div className="error-content">
        <h1>
          <i className="fas fa-exclamation-triangle text-warning"></i> Oops! Page not found.
        </h1>

        <p>
          We could not find the page you were looking for.
          Meanwhile, you may return to Dashboard to proceed!
        </p>

        <p>
          {props.isLoggedIn ? (
            <button onClick={handleReturnToDashboard('/1')} className="btn btn-warning">
              Click here to return to Dashboard
            </button>
          ) : (
            <button onClick={handleReturnToDashboard('/')} className="btn btn-warning">
              Click here to return to Dashboard
            </button>
          )}
        </p>
      </div>
    </div>
  );
};

export default PageNotFound;
