import React, { useState, useRef, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SetNewPassword from "./SetNewPassword";
import applicantContext from "../context/ApplicantContext";
const ForgotPassword = (props) => {
  const context = useContext(applicantContext);
  const {showSuccessMessage,  showErrorMessage} = context;
  const refOpen = useRef(null);
  useEffect(() => {
    props.setProgress(25);
    props.setProgress(50);
    props.setProgress(75);
    props.setProgress(100);

  }, []);

  const [otpSent, setOtpSent] = useState(false);
  const [OTP, SetOTP] = useState('');
  const [OTPinput, setOTPinput] = useState([0, 0, 0, 0]);
  const [email, setEmail] = useState('');
  const [verificationBox, setVerificationBox] = useState(false);
  const [loadingTime, setLoadingTime] = useState(false);
  let history = useNavigate();


  const sendOTP = async (e) => {

    e.preventDefault();
    if (email.length < 5) {
      showErrorMessage("Email Cannot be less than 5 Characters..", "ERROR");
      document.querySelector("#email").classList.remove("is-valid");
      document.querySelector("#email").classList.add("is-invalid");
      
      return;
    } 
    else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      showErrorMessage("Email is Incorrect. Please Enter valid Email address", "ERROR");

      document.querySelector("#email").classList.remove("is-valid");
      document.querySelector("#email").classList.add("is-invalid");
      
      return;
    }
// getting the Email from db is it exsists.. 
// props.setProgress(20);
const response = await fetch(`${process.env.REACT_APP_BACKEND_ADDRESS}api/userRoutes/getEmailAddress`, {
  method: "POST", 
  headers: { "Content-Type": "application/json" },  
  body: JSON.stringify({ email: email })});
//  props.setProgress(40);

const json = await response.json();
if(!json.success) {
  showErrorMessage("Email Address is not linked with any Account. Please check again", "ERROR");
  // props.setProgress(100);

  return false;
}
showSuccessMessage("Account exists. Email Sending is in Progress..");
setLoadingTime(true);

  // Now Generate the OTP and Email to the Applicant.....
  const tempOTP = Math.floor(Math.random() * 9000 + 1000);
  SetOTP(tempOTP); 
// Now sending this OTP via Email.....

const emailResponse = await fetch(`${process.env.REACT_APP_BACKEND_ADDRESS}api/userRoutes/sendEmail`,
{ method: "POST",
headers: {
  "Content-Type": "application/json"},
body: JSON.stringify({
  "to": email,
  "subject": `Password Reset of ${email} - Admissions, UoP`,
  "html": `<!DOCTYPE html>
  <html lang="en" >
  <head>
    <meta charset="UTF-8">
    <title>CodePen - OTP Email Template</title>
    
  </head>
  <body>
  <!-- partial:index.partial.html -->
  <div style="font-family: Helvetica,Arial,sans-serif;min-width:1000px;overflow:auto;line-height:2">
    <div style="margin:30px auto;width:70%;padding:20px 0">
      <div style="border-bottom:1px solid #eee">
        <a href="" style="font-size:1.4em;color: #00466a;text-decoration:none;font-weight:600">Applicant Password Reset Request</a>
      </div>
      <p style="font-size:1.1em">Dear Applicant,</p>
      <p>A password reset request is received from you. Please use the following OTP to complete your Password Recovery Procedure. Please note that this OTP is only valid for 10 minutes.</p>
      <h2 style="background: #00466a;margin: 0 auto;width: max-content;padding: 0 10px;color: #fff;border-radius: 4px;">${tempOTP}</h2>
      <p style="font-size:0.9em;">Regards,<br />Admissions Office<br/>University of Peshawar</p>
      <hr style="border:none;border-top:1px solid #eee" />
      <div style="float:right;padding:8px 0;color:#aaa;font-size:0.8em;line-height:1;font-weight:300">
        <p>Helpdesk, Admissions Office</p>
        <p>University of Peshawar, Pakistan</p>
        <p>Contact Number: 0092-91-9222200</p>

      </div>
    </div>
  </div>
  <!-- partial -->
    
  </body>
  </html>`

})});
// props.setProgress(75);

const jsonEmail = await emailResponse.json();
if(jsonEmail.success) {
  setLoadingTime(false);
  setOtpSent(true);
  // props.setProgress(100);

  showSuccessMessage("OTP is sent on your Email Address. Please check your Email Inbox/ SPAM folder", "OTP SENT")

}
else {
  // props.setProgress(100);

  showErrorMessage("Unable to send OTP on your Email Address. Please Try again later", "ERROR");

}



  }
// Now verify OTP

const verifyOTP= () => {


  if (parseInt(OTPinput.join("")) !== OTP) {
    showErrorMessage("You have Entered Incorrect OTP. Please Try Again", "Incorrect OTP");

    return;
  }
  else
  {
    setOtpSent(false);
    showSuccessMessage("OTP Matched. Please Enter New Password", "Success");

    // Now showing a Modal to set new password....
    setVerificationBox(true);
    refOpen.current.click();



  }


  };

  

  return (
    <>
     <div className="container-fluid" >

<div className="row align-items-start" style={{ background: '#fff', margin:'40px', padding:'20px'}}>

<div className="col-sm-8 my-3" > 
<center> <h2> <img
src="/uop-logo-png.png"
alt="Academic Block"
width="80px"
/>  University of Peshawar Graduate Admissions 2023</h2></center> 
   
<p style={{margin:'20px', fontSize:'18px', textAlign:'justify'}}> <img
src="/backCover23.jpeg"
className="rounded img-fluid"
alt="Academic Block"
width="400px"
style={{margin:'10px', float:'left'}}
/> 
<br/>
<span style={{fontSize:'30px'}}><strong>W</strong></span>elcome to online Admissions Portal of University of Peshawar for the session 2023-2024. To facilitate the 
applicant, a short video tutorial regarding applying for admissions for different programs is available.</p>
<button
                      type="button"
                      className="btn btn-danger text-nowrap"
                      data-toggle="modal"
                      data-target="#modal-lg2"
                    >
                      Click here to Watch Video Tutorial
                    </button>
  </div>
<div className="col-sm-4 my-3" >
<div className="container">
<center><Link to="/login" className="btn btn-danger text-nowrap mx-3 my-3">Go Back to Login Page</Link></center>
    <button style={{display: 'none'}} type="button" ref={refOpen} className="btn btn-danger text-nowrap" data-toggle="modal" data-target="#modal-xl">
                  Apply Now
                </button>     
                <div
              className="card card-outline card-warning"
              style={{ marginTop: "1em important" }}
            >
              <div className="card-header text-center">
                               <h3>Password Reset Page</h3>
                <p style={{marginTop: '40px'}}>Dear Applicant, please enter your registered email address below:</p>

                  <form onSubmit={sendOTP} className="form-horizontal">
                    <div className="input-group">
                      <input
                        type="text"
                        onChange={(e) =>{
                          setEmail(e.target.value);
                        }}
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder="Enter Your Email Address"
                      />
                      <div className="input-group-append">
                        <input
                        
                          type="submit"
                          className="btn btn-warning"
                          value="Send OTP"
                        />
                      </div>
                    </div>
                  </form>
                </div></div>
                {loadingTime ? (
 <div className="container" >
 <span style={{fontWeight: 'bolder'}}>Sending Email.... please wait !!</span>
</div>
                ):''}
                {otpSent ? (
                  <div className="container" >
                    <span style={{fontWeight: 'bolder'}}>Dear Applicant, Please Enter the One Time Password (OTP) you received in your Email Inbox or Spam Folder.</span>
                      <div className="input-group my-4">
                        <input
                          type="text"
                          maxLength="1"
                          name=""
                          id="op1"
                          className="form-control"
                          onChange={(e) =>{
                            setOTPinput([
                              e.target.value,
                              OTPinput[1],
                              OTPinput[2],
                              OTPinput[3],
                            ]);       document.querySelector('#op2').focus();
                          }
                          }
                        />
                        <input
                          type="text"
                          maxLength="1"
                          name=""
                          id="op2"
                          className="form-control"
                          onChange={(e) =>
                           { setOTPinput([
                              OTPinput[0],
                              e.target.value,
                              OTPinput[2],
                              OTPinput[3],
                            ]);  document.querySelector('#op3').focus();}
                          }
                        />
                        <input
                          type="text"
                          maxLength="1"
                          name=""
                          id="op3"
                          className="form-control"
                          onChange={(e) =>
                           { setOTPinput([
                              OTPinput[0],
                              OTPinput[1],
                              e.target.value,
                              OTPinput[3],
                            ]);  document.querySelector('#op4').focus();}
                          }
                        />
                        <input
                          type="text"
                          maxLength="1"
                          name=""
                          id="op4"
                          className="form-control"
                          onChange={(e) =>
                            setOTPinput([
                              OTPinput[0],
                              OTPinput[1],
                              OTPinput[2],
                              e.target.value,
                            ])
                          }
                        />
                        <div className="input-group-append">
                          <input
                            type="button"
                            onClick={verifyOTP}
                            className="btn btn-success"
                            value="Click here to Verify OTP"
                          />
                        </div>
                      </div>
                  </div>
                ) : (
                  ""
                )}
               <SetNewPassword email={email} />
        </div>
          </div>
         
        </div>
    </div>

    
    


               <div className="modal fade" id="modal-lg2">
              <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">x</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="embed-responsive embed-responsive-16by9">
                      <iframe
    className="embed-responsive-item"
    src={process.env.REACT_APP_VIDEO_TUTORIAL}
    title="Embedded YouTube Video"
    allowFullScreen
  ></iframe>
</div>
                          
                         
                      
                       

                       
                    </div>
                    <div className="modal-footer justify-content-between">
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-dismiss="modal"
                      >
                        Close
                      </button>

                     
                    </div>
                </div>
              </div>
            </div>
    </>
  );
};

export default ForgotPassword;
