import React, {useState, useRef, useContext} from 'react'
import applicantContext from "../context/ApplicantContext";
import  secureLocalStorage  from  "react-secure-storage";

const AdvertismentApply = (props) => {
  const context = useContext(applicantContext);
  const {showSuccessMessage } = context;
  const [isChecked, setIsChecked] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const refClose = useRef(null);

  const handleSubmit = async (e) =>{
    e.preventDefault();
    setDisableButton(true);
    const response = await fetch(`${process.env.REACT_APP_BACKEND_ADDRESS}api/applicationRoutes/addApplication`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": secureLocalStorage.getItem('token')
      },
      body: JSON.stringify({
        
      advertismentId: props.applyData.advertismentId,
      advertismentProgramId: props.applyData.advertismentProgramId,
      quotas: props.applyData.applyQuota,
      hq_status: props.applyData.hq_status,
      consumer_Detail: props.applyData.consumer_Detail,
      Due_Date: props.applyData.Due_Date,
      amount: props.applyData.amount,
      amount_After_DueDate: props.applyData.amount_After_DueDate,
      reserved: props.applyData.reserved


      }),
    });
    const json = await response.json();
    if(json.success) {

      // Lock the profile here.....
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_ADDRESS}api/userRoutes/updateField`,
        {
          method: "POST", headers: { "Content-Type": "application/json","auth-token": secureLocalStorage.getItem("token") },
          body: JSON.stringify({ "fieldName": "lockProfile", "fieldValue": "true"})
        });
        const str = await response.json();
        if(str.success)
        {
          showSuccessMessage("Application Submitted Successfully","Success");
          secureLocalStorage.setItem("lockProfile",'true');
          setDisableButton(false);
          refClose.current.click();
         props.setUpdateCounter(props.updateCounter + 1);
        }

      
    
     
    
    
    } else {
      alert(json.error);
    }








  }
  return (
    <>
      <div className="modal fade" id="modal-xl">
  <div className="modal-dialog modal-xl">
    <form onSubmit={handleSubmit}>
    <div className="modal-content">
      <div className="modal-header">
        <h4 className="modal-title">Candidate Application Form</h4>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">x</span>
        </button>
      </div>
      <div className="modal-body">
      <div className='text-danger p-3 bg-danger'>Please review below information very carefully. Incase if there is any mistake in below information, please go back to the relevant section and update it first. Once you submit your application, you will not be
             able to make any changes in future.</div><br/>
             <p style={{fontSize: '18px', fontFamily: 'Nafees Naskh', textAlign:'right', fontWeight:'bold'}}>برائے مھربانی نیچے دی گئی معلومات کا بہت احتیاط سے جائزہ لیں۔ اگر اس میں کوئی غلطی ہے تو متعلقہ سیکشن میں واپس جائیں اور پہلے اسے ٹھیک کریں۔ ایک بار جب آپ اپنی درخواست جمع کروا لیں تو آپ مستقبل میں کوئی تبدیلی نہیں کر سکیں گے۔</p>

             <h3 className='p-3 bg-gray-dark'>Profile Information:</h3>
            <table className="table table-bordered">
               
                <tbody>
                    <tr>
                        <td style={{width: '160px'}} ><strong>Applicant Name:</strong></td><td>{props.applicant.name}</td>
                        <td style={{width: '160px'}} ><strong>Father Name:</strong></td><td>{props.applicant.fatherName}</td>
                        <td style={{width: '160px'}} ><strong>Profile Picture:</strong></td>
                        <td>{props.applicant.picture}
                          {props.applicant.picture!==''?
                        <img
                              className=" mx-3"
                              // src={`${process.env.REACT_APP_BACKEND_ADDRESS}static/` + applicantDocuments.picture}
                              src={`${process.env.REACT_APP_BACKEND_ADDRESS}static/${props.applicant.profilePicture}`}
                              style={{
                                width: "70px",
                                height: "90px",
                                border: "1px solid gray",
                              }}
                            />:''}
                            </td>
                    </tr>
                    <tr>
                        <td style={{width: '160px'}} ><strong>Date of Birth:</strong></td><td>{props.applicant.dob}</td>
                        <td style={{width: '160px'}} ><strong>CNIC:</strong></td><td>{props.applicant.cnic}</td>
                        <td style={{width: '160px'}} ><strong>Religion:</strong></td><td>{props.applicant.religion}</td>
                    </tr>
                    <tr>
                        <td style={{width: '160px'}} ><strong>Gender:</strong></td><td>{props.applicant.gender}</td>
                        <td style={{width: '160px'}} ><strong>Marital Status:</strong></td><td>{props.applicant.maritalStatus}</td>
                        <td style={{width: '160px'}} ><strong>Landline #:</strong></td><td>{props.applicant.landline}</td>
                    </tr>
                    <tr>
                        <td style={{width: '160px'}} ><strong>Mobile #:</strong></td><td>{props.applicant.contact}</td>
                        <td style={{width: '160px'}} ><strong>Province:</strong></td><td>{props.applicant.province}</td>
                        <td style={{width: '160px'}} ><strong>Domicile:</strong></td><td>{props.applicant.domicile}</td>
 </tr>
                    <tr>
                        <td style={{width: '160px'}} ><strong>Hafiz Quran:</strong></td><td>{props.applicant.hafizQuran}</td>
                        <td style={{width: '160px'}} ><strong>Mailing Address:</strong></td><td colSpan="3">{props.applicant.address}</td>
   </tr>
              
                   
                </tbody>
               
            </table>
           <h3 className='p-3 bg-gray-dark'>Academic Qualification:</h3> 
            <table className="table table-bordered">
            <thead>
      <tr>
        <th>Examination</th>
        <th>Board</th>
        <th>Obtained Marks/ CGPA</th>
        <th>Total Marks/ CGPA</th>
        <th>Percentage</th>

      </tr>
    </thead>
                <tbody>
             
                       <tr>
        <td>{props.applicant.degreeTitle}</td>
        <td>{props.applicant.degreeFrom}</td>
        <td>{props.applicant.obtainedMarks}</td>
        <td>{props.applicant.totalMarks}</td>
        <td>{props.applicant.obtainedMarks*100/props.applicant.totalMarks+' %'}</td>
        

      </tr>

           
                </tbody>
               
            </table>
            <h3 className='p-3 bg-gray-dark'>Admission Test Record:</h3> 
            <table className="table table-bordered">
            <thead>
      <tr>
        <th>Title</th>
        <th>Passing Date</th>
        <th>Expiry Date</th>
        <th>Roll No.</th>
        <th>Obtained Marks</th>
        <th>Total Marks</th>
      </tr>
    </thead>
                <tbody>
              
                       <tr>
        <td>{props.applicant.testTitle}</td>
        <td>{props.applicant.passingDate}</td>
        <td>{props.applicant.expiryDate}</td>
        <td>{props.applicant.testRollNo}</td>
        <td>{props.applicant.testObtainedMarks}</td>
        <td>{props.applicant.testTotalMarks}</td>
        

      </tr>

              
                </tbody>
               
            </table>
            <h3 className='p-3 bg-gray-dark'>Apply Details:</h3> 
            <table className="table table-bordered p-3">
               
                <tbody>
                    <tr>
                        <td style={{width: '90px'}} ><strong>Advertisment:</strong></td><td>{props.applyData.advertismentName}</td>
                        <td style={{width: '70px'}} ><strong>Program:</strong></td><td>{props.applyData.programName}</td>
                        <td style={{width: '60px'}} ><strong>Quota:</strong></td><td>{props.applyData.applyQuota}</td>
                    </tr>
                    </tbody>
                    </table>
                    <h3 className='p-3 bg-gray-dark'>Declaration:</h3>
<div className='container mx-4'> <input checked={isChecked} onChange={(e) =>{ setIsChecked(!isChecked)}} type="checkbox" name='declaration' id="declaration" className='form-check-input' />
       <label className='form-check-label text-danger' htmlFor='declaration'><strong>I hereby declare that the Information above is correct to the best of my knowledge. Once i click "Apply", i will not be able to update my Profile Information, Qualification and Uploaded Documents.</strong></label>
  </div>
          </div>
      <div className="modal-footer justify-content-between">
        <button type="button" ref={refClose} className="btn btn-default" data-dismiss="modal">Close</button>
        <input type="submit"  
         disabled={
          disableButton || !isChecked
            ? "disabled"
            : ""
        }
        className="btn btn-danger" value=" Apply Now " />
      </div>
    </div>
          </form>

  </div>
</div>
    </>
  )
}

export default AdvertismentApply
