import React from 'react'

const FooterDefault = () => {
    return (
        <footer className="footer fixed-bottom">

      <div style={{borderTop: '1px solid grey', backgroundColor: "#fff", padding: '5px'}}>
    <strong>Copyright ©  2024 |  Version 2.1.0</strong>
    <div className="float-right d-none d-sm-inline-block mx-3">
      <b>All rights reserved</b> 
    </div>
  
     </div>
     </footer>
    )
  }

export default FooterDefault
