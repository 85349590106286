import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import applicantContext from "../context/ApplicantContext";
import  secureLocalStorage  from  "react-secure-storage";

const Home1 = (props) => {
  const context = useContext(applicantContext);
  const {
    applicant,
    setApplicant,
    getApplicantProfile,
    authenticateApplicant,
    applicantStatus,
    showSuccessMessage,
    showErrorMessage
  } = context;

  let history = useNavigate();

  useEffect(() => {
    props.setProgress(25);
    authenticateApplicant()
      .then((isAuthenticated) => {
        if (isAuthenticated) {
          props.setProgress(60);
          getApplicantProfile();
          props.setProgress(100);

        } else {
          secureLocalStorage.clear();
          history("/login");
        }
      });
  }, []);

  

  const onChange = (e) => {
    setApplicant({ ...applicant, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Now Validation
    const dateRegex = /^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-(\d{4})$/;

    if (applicant.name.length < 4) {
      
      showErrorMessage("Name cannot be less than 4 Characters","Error");
      document.querySelector("#name").classList.remove("is-valid");
      document.querySelector("#name").classList.add("is-invalid");
      document.querySelector("#name").focus();
      
      return;
    } else if (applicant.fatherName.length < 5) {
      document.querySelector("#name").classList.remove("is-invalid");
      document.querySelector("#name").classList.add("is-valid");
      
      showErrorMessage("Father Name cannot be less than 5 Characters","Error");

      document.querySelector("#fatherName").classList.remove("is-valid");
      document.querySelector("#fatherName").classList.add("is-invalid");
      document.querySelector("#fatherName").focus();
      
      return;
    } else if (applicant.dob.length < 8) {
      document.querySelector("#fatherName").classList.remove("is-invalid");
      document.querySelector("#fatherName").classList.add("is-valid");
            showErrorMessage("Date of Birth Field Cannot be Empty","Error");

      document.querySelector("#dob").classList.remove("is-valid");
      document.querySelector("#dob").classList.add("is-invalid");
      document.querySelector("#dob").focus();

      
      return;
    } else if ( !dateRegex.test(applicant.dob)) {
      document.querySelector("#fatherName").classList.remove("is-invalid");
      document.querySelector("#fatherName").classList.add("is-valid");
      
      showErrorMessage("Date of Birth Must be in the format DD-MM-YYYY","Error");

      document.querySelector("#dob").classList.remove("is-valid");
      document.querySelector("#dob").classList.add("is-invalid");
      document.querySelector("#dob").focus();

      
      return;
    }
    else if (applicant.cnic.length < 5) {
      document.querySelector("#dob").classList.remove("is-invalid");
      document.querySelector("#dob").classList.add("is-valid");
      showErrorMessage("CNIC/ Form-B/ Passport No. must be provided","Error");

      document.querySelector("#cnic").classList.remove("is-valid");
      document.querySelector("#cnic").classList.add("is-invalid");
      document.querySelector("#cnic").focus();

      
      return;
    } 
    else if (applicant.religion.length < 5) {
      document.querySelector("#cnic").classList.remove("is-invalid");
      document.querySelector("#cnic").classList.add("is-valid");
      showErrorMessage("Religion must be selected","Error");

      document.querySelector("#religion").classList.remove("is-valid");
      document.querySelector("#religion").classList.add("is-invalid");
      document.querySelector("#religion").focus();

      
      return;
    } else if (applicant.gender.length < 3) {
      document.querySelector("#religion").classList.remove("is-invalid");
      document.querySelector("#religion").classList.add("is-valid");
      showErrorMessage("Gender must be selected","Error");

      document.querySelector("#gender").classList.remove("is-valid");
      document.querySelector("#gender").classList.add("is-invalid");
      document.querySelector("#gender").focus();

      
      return;
    } else if (applicant.maritalStatus.length < 6) {
      document.querySelector("#gender").classList.remove("is-invalid");
      document.querySelector("#gender").classList.add("is-valid");     
       showErrorMessage("Marital Status must be selected","Error");

      document.querySelector("#maritalStatus").classList.remove("is-valid");
      document.querySelector("#maritalStatus").classList.add("is-invalid");
      document.querySelector("#maritalStatus").focus();

      
      return;
    } 
    else if (applicant.province.length < 5) {
      document.querySelector("#maritalStatus").classList.remove("is-invalid");
      document.querySelector("#maritalStatus").classList.add("is-valid");
      showErrorMessage("Select Province value","Error");

      document.querySelector("#province").classList.remove("is-valid");
      document.querySelector("#province").classList.add("is-invalid");
      document.querySelector("#province").focus();

      
      return;
    } else if (applicant.domicile.length < 3) {
      document.querySelector("#province").classList.remove("is-invalid");
      document.querySelector("#province").classList.add("is-valid");
      showErrorMessage("Enter Domicile Value","Error");

      document.querySelector("#domicile").classList.remove("is-valid");
      document.querySelector("#domicile").classList.add("is-invalid");
      document.querySelector("#domicile").focus();

      
      return;
    } 
    else if (applicant.contact.length !== 11 || isNaN(applicant.contact)) {
      document.querySelector("#domicile").classList.remove("is-invalid");
      document.querySelector("#domicile").classList.add("is-valid");
      
      showErrorMessage("Enter a valid 11-digit Contact Number i.e. '03001234567'", "Error");
    
      document.querySelector("#contact").classList.remove("is-valid");
      document.querySelector("#contact").classList.add("is-invalid");
      document.querySelector("#contact").focus();
    
      return;
    }
    else if (applicant.hafizQuran.length < 2) {
      document.querySelector("#contact").classList.remove("is-invalid");
      document.querySelector("#contact").classList.add("is-valid");
      showErrorMessage("Hafiz Quran Status must be provided","Error");
      document.querySelector("#hafizQuran").classList.remove("is-valid");
      document.querySelector("#hafizQuran").classList.add("is-invalid");
      document.querySelector("#hafizQuran").focus();

      
      return;
    }
    else if (applicant.nationality.length < 3) {
      document.querySelector("#hafizQuran").classList.remove("is-invalid");
      document.querySelector("#hafizQuran").classList.add("is-valid");
      showErrorMessage("Select Nationality","Error");

      document.querySelector("#nationality").classList.remove("is-valid");
      document.querySelector("#nationality").classList.add("is-invalid");
      document.querySelector("#nationality").focus();

      
      return;
    }
    else if (applicant.address.length < 3) {
      document.querySelector("#nationality").classList.remove("is-invalid");
      document.querySelector("#nationality").classList.add("is-valid");
      showErrorMessage("Enter a valid Mailing Address","Error");

      document.querySelector("#address").classList.remove("is-valid");
      document.querySelector("#address").classList.add("is-invalid");
      document.querySelector("#address").focus();

      
      return;
    } 
    


    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}api/applicantRoutes/updateApplicant`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": secureLocalStorage.getItem("token"),
        },
        body: JSON.stringify(applicant),
      }
    );

    const json = await response.json();
    if (json.success) {
      showSuccessMessage("Your Profile Information is Updated Successfully !","SUCCESS");

      // updating the step2 true status....
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_ADDRESS}api/userRoutes/updateField`,
        {
          method: "POST", headers: { "Content-Type": "application/json","auth-token": secureLocalStorage.getItem("token") },
          body: JSON.stringify({ "fieldName": "step2", "fieldValue": "true"})
        });
        const str = await response.json();
        if(str.success)
      secureLocalStorage.setItem("step2", 'true');
              history("/2");

    } else {
      console.log(json.error);
      showErrorMessage(json.error,"ERROR");

      //alert(json.error);
    }
  };

  return (
    <>
      <div className="content-wrapper" style={{ minHeight: "700px" }}>
        <div className="content-header">
          {/* <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Applicant Portal</h1>
              </div>
            </div>
          </div> */}
        </div>
        <section className="content">
          <div className="card card-primary card-outline">
            <div className="card-header">
              <h5 className="card-title"><strong>Step 1  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg> Personal Information Section</strong></h5>
            </div>

            <div className="card-body pb-0">
              <div className="row">
                <div className="col-md-12">
                  {/* general form elements */}
                  <div className="card card-warning">
                    <div className="card-header">
                      <h3 className="card-title"> Applicant Profile:</h3>  
                    </div>
                   
                    <form onSubmit={handleSubmit}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              
                              <label htmlFor="cname">Name: <span style={{ color:'red' }} >*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={applicant.name}
                                onChange={onChange}
                                placeholder="Enter Name"
                              />
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="fatherName">Father Name: <span style={{ color:'red' }} >*</span></label>
                              <input
                                type="fatherName"
                                className="form-control"
                                id="fatherName"
                                name="fatherName"
                                onChange={onChange}
                                value={applicant.fatherName}
                                placeholder="Enter Father Name"
                              />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="cname">Date of Birth (DD-MM-YYYY): <span style={{ color:'red' }} >*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                id="dob"
                                name="dob"
                                onChange={onChange}
                                placeholder="DD-MM-YYYY"
                                value={applicant.dob}
                              />
                            </div>
                          </div>
                        </div>
                       
                        <div className="row">
                        <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="cnic">CNIC/ Passport No: <span style={{ color:'red' }} >*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                id="cnic"
                                name="cnic"
                                onChange={onChange}
                                placeholder="Enter CNIC/ Form-B Number"
                                value={applicant.cnic}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Religion: <span style={{ color:'red' }} >*</span></label>
                              <select
                                onChange={onChange}
                                className="form-control"
                                name="religion"
                                id="religion"
                                style={{ width: "100%" }}
                                value={applicant.religion}
                              >
                                <option> Select Religion: </option>
                                <option value="Muslim">Muslim</option>
                                <option value="Non-Muslim">Non-Muslim</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <label>Gender: <span style={{ color:'red' }} >*</span></label>

                            <div className="form-group" onChange={onChange}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  onChange={() => {}}
                                  type="radio"
                                  checked={applicant.gender === "male"}
                                  value="male"
                                  id="gender"
                                  name="gender"
                                />
                                <label className="form-check-label mx-2">
                                  Male
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  onChange={() => {}}
                                  type="radio"
                                  checked={applicant.gender === "female"}
                                  value="female"
                                  id="gender"
                                  name="gender"
                                />
                                <label className="form-check-label mx-3">
                                  Female
                                </label>
                              </div>
                            </div>
                          </div>

                        
                        </div>
                        <div className="row">
                        <div className="col-sm-4">
                            <label>Marital Status: <span style={{ color:'red' }} >*</span></label>

                            <div className="form-group" onChange={onChange}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  onChange={() => {}}
                                  value="Single"
                                  checked={
                                    applicant.maritalStatus === "Single"
                                  }
                                  type="radio"
                                  id="maritalStatus"
                                  name="maritalStatus"
                                />
                                <label className="form-check-label mx-2">
                                  Single
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  onChange={() => {}}
                                  value="Married"
                                  checked={
                                    applicant.maritalStatus === "Married"
                                  }
                                  type="radio"
                                  id="maritalStatus"
                                  name="maritalStatus"
                                />
                                <label className="form-check-label mx-3">
                                  Married
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Province: <span style={{ color:'red' }} >*</span> </label>
                              <select
                                value={applicant.province}
                                onChange={onChange}
                                className="form-control"
                                id="province"
                                name="province"
                                style={{ width: "100%" }}
                              >
                                <option>-- Select Province --</option>
                                <option value="Khyber Pakhtunkhwa">Khyber Pakhtunkhwa</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Sindh">Sindh</option>
                                <option value="Balochistan">Balochistan</option>
                                <option value="Gilgit Baltistan">Gilgit Baltistan</option>
                                <option value="Azad Kashmir">Azad Kashmir</option>
                                <option value="Other">Other</option>



                              </select>
                            </div>
                          </div>

                          <div className="col-sm-4">
                              <div className="form-group">
                                <label>Enter Domicile: <span style={{ color:'red' }} >*</span></label>
                                <input
                                  onChange={onChange}
                                  value={
                                    applicant.domicile 
                                  }
                                  className="form-control"
                                  id="domicile"
                                  name="domicile"
                                  style={{ width: "100%" }}
                                />
                                  
                           
                              </div>
                          </div>
                        
                         
                        </div>
                        <div className="row">
                        <div className="col-sm-4">
                            <div className="form-group">
                              <label>Landline No:</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fas fa-phone"></i>
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="landline"
                                  id="landline"
                                  onChange={onChange}
                                  value={applicant.landline}
                                  placeholder="Enter Contact #"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label>Mobile No: <span style={{ color:'red' }} >*</span></label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fas fa-phone"></i>
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Contact #"
                                  name="contact"
                                  onChange={onChange}
                                  id="contact"
                                  value={applicant.contact}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <label>Are you Hafiz-e-Quran ? <span style={{ color:'red' }} >*</span></label>

                            <div className="form-group" onChange={onChange}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  onChange={() => {}}
                                  value="Yes"
                                  checked={applicant.hafizQuran === "Yes"}
                                  type="radio"
                                  id="hafizQuran"
                                  name="hafizQuran"
                                />
                                <label className="form-check-label mx-2">
                                  Yes
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  onChange={() => {}}
                                  value="No"
                                  checked={applicant.hafizQuran === "No"}
                                  type="radio"
                                  id="hafizQuran"
                                  name="hafizQuran"
                                />
                                <label className="form-check-label mx-3">
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                         
                        </div>
                      

                        <div className="row">
                        <div className="col-sm-4">
                            <div className="form-group">
                              <label>Nationality: <span style={{ color:'red' }} >*</span> </label>
                              <select
                                value={applicant.nationality}
                                onChange={onChange}
                                className="form-control"
                                id="nationality"
                                name="nationality"
                                style={{ width: "100%" }}
                              >
                                <option>-- Select Nationality --</option>
                                <option value="Pakistani">Pakistani</option>
                                <option value="Afghanistan">Afghanistan</option>
                                <option value="Foreigner">Foreigner</option>
                               



                              </select>
                            </div>
                          </div>
                        <div className="col-sm-8">
                            <div className="form-group">
                              <label>Mailing Address: <span style={{ color:'red' }} >*</span></label>
                              <textarea
                                onChange={onChange}
                                className="form-control"
                                rows="1"
                                name="address"
                                id="address"
                                value={applicant.address}
                                placeholder="Enter your Postal Adress"
                              ></textarea>
                            </div>
                          </div>

                       
                         
                        </div>
                        
                      </div>
                      <div className="card-footer">
                        <div
                          className="btn-group float-right"
                          role="group"
                          aria-label="Buttons"
                        >
                          {secureLocalStorage.getItem("lockProfile") === "false"?(
                          <input

                            type="submit"
                            value=" Save & Next "
                            name="Submit"
                            id="Submit"
                            className="btn btn-warning btn-flat"
                          /> ):''}
                         
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home1;
