import React from 'react'

const Footer = () => {
  return (
    <div>
<footer className="main-footer">
    <strong>Copyright © 2024 | Version: 2.1.0 </strong>
    <div className="float-right d-none d-sm-inline-block mx-3">
      <b>All rights reserved</b> 
    </div>
  
</footer>

   </div>
  )
}

export default Footer
