import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import Signup from "./components/Signup";
import Home1 from "./components/Home1";
import Home2 from "./components/Home2";
import Home5 from "./components/Home5";
import Home3 from "./components/Home3";
import Home4 from "./components/Home4";
import Navbar from "./components/Navbar";
import Footer from './components/Footer'
import SideNav from './components/SideNav'
import PageNotFound from "./components/PageNotFound";
import LoadingBar from 'react-top-loading-bar'
import FooterDefault from "./components/FooterDefault";
import  secureLocalStorage  from  "react-secure-storage";


function App() {
  
  const [isLoggedIn, setIsLoggedIn] = useState(
    secureLocalStorage.getItem("isLoggedIn") === "true"
  );
  
  const [progress, setProgress] = useState(0);
  
  useEffect(() => {
    secureLocalStorage.setItem("isLoggedIn", JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);
  


  return (
    <>

    <LoadingBar
        color='#f11946'
        progress={progress}
        height={4}
      />
    {isLoggedIn? (<>

<Navbar setIsLoggedIn={setIsLoggedIn} />
<SideNav />
<Routes>
 
<Route exact path="/1" element={<Home1 setProgress={setProgress} />} />
<Route exact path="/2" element={<Home2  setProgress={setProgress} />} />
<Route exact path="/3" element={<Home3  setProgress={setProgress} />} />
<Route exact path="/4" element={<Home4 setProgress={setProgress}  />} />
<Route exact path="/5" element={<Home5 setProgress={setProgress}  />} />
<Route path="*" element={<PageNotFound isLoggedIn={isLoggedIn} />} />

</Routes>
<Footer />

</> ):


<><Routes>

<Route exact path="/" element={<Login   setIsLoggedIn={setIsLoggedIn} setProgress={setProgress} />}  />
<Route exact path="/login" element={<Login   setIsLoggedIn={setIsLoggedIn} setProgress={setProgress} />} />
  <Route exact path="/forgotPassword" element={<ForgotPassword  setProgress={setProgress}   />} />
<Route exact path="/signup" element={<Signup setProgress={setProgress}    />} />
<Route path="*" element={<PageNotFound isLoggedIn={isLoggedIn} />} />

</Routes>
<FooterDefault />
</>
}




 </>
  )
}

export default App;
