import React, { useState, useRef, useContext } from "react";
import {useNavigate} from "react-router-dom";

import applicantContext from "../context/ApplicantContext";


const SetNewPassword = (props) => {
  let history = useNavigate();

  const context = useContext(applicantContext);
  const {showSuccessMessage,  showErrorMessage} = context;
    // const refOpen = useRef(null);
    const refClose = useRef(null);
  
    const [credentials, setCredentials] = useState({
      password: "",
      cpassword: ""
    });
    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
      };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const {email, password, cpassword } = credentials;

        if(password.length <8){

          showErrorMessage("Password Cannot be less than 8 Characters..", "ERROR");
          document.querySelector("#password").classList.remove("is-valid");
            document.querySelector("#password").classList.add("is-invalid");
            document.querySelector('#password').focus();
      
            
            return;
          } 
          else if(!(password === cpassword)){
      
            showErrorMessage("Password & Confirm Password values must match", "ERROR");

            document.querySelector("#password").classList.remove("is-valid");
            document.querySelector("#password").classList.add("is-invalid");
            document.querySelector("#cpassword").classList.remove("is-valid");
            document.querySelector("#cpassword").classList.add("is-invalid");
            document.querySelector('#cpassword').focus();
            return;
          }

          // Now update Function of DB....
          const response = await fetch(`${process.env.REACT_APP_BACKEND_ADDRESS}api/userRoutes/updatePassword`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: props.email,
              password: password,
            }),
          }
        );
        const json = await response.json();
    
        if (json.success) {
          showSuccessMessage("Password Updated Successfully. Please Login now to continue..", "Success")
          refClose.current.click();
          history("/login");

        } else {
          showErrorMessage("Unable to update New Password. Please try again later..", "ERROR");
        }
    }


  return (
    <>
  {/* <button type="button" ref={refOpen} className="btn btn-danger" data-toggle="modal" data-target="#modal-xl">
                  Apply Now
                </button>      */}
                <div className="modal fade" id="modal-xl">
  <div className="modal-dialog">
    <div className="modal-content">
    <form onSubmit={handleSubmit}>

      <div className="modal-header">
        <h4 className="modal-title">Set New Password</h4>
        <button ref={refClose}  type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">x</span>
        </button>
      </div>
      <div className="modal-body">
     
      
       <div className='container'>
                      
        <div className="input-group mb-3">
                          <input
                            type="text"
                            value={props.email}
                            disabled="disabled"
                            className="form-control"
                            id="email2"
                            name="email2"
                          />
                          <div className="input-group-append">
                            <div className="input-group-text">
                              <span className="fas fa-lock"></span>
                            </div>
                          </div>
                        </div>
                        <div className="input-group mb-3">
                          <input
                            type="password"
                            value={credentials.password}
                            onChange={onChange}
                            className="form-control"
                            id="password"
                            name="password"
                            placeholder="Enter New Password"
                          />
                          <div className="input-group-append">
                            <div className="input-group-text">
                              <span className="fas fa-lock"></span>
                            </div>
                          </div>
                        </div>

                        <div className="input-group mb-3">
                          <input
                            type="password"
                            value={credentials.cpassword}
                            onChange={onChange}
                            className="form-control"
                            id="cpassword"
                            name="cpassword"
                            placeholder="Enter Confirm Password "
                          />
                          <div className="input-group-append">
                            <div className="input-group-text">
                              <span className="fas fa-lock"></span>
                            </div>
                          </div>
                        </div>
                        <div className="input-group mb-3">
                         
                        </div>
                     

       </div>
      </div>
      <div className="modal-footer justify-content-between">
        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-primary">Change Password</button>
      </div>

      </form>
    </div>
    {/* /.modal-content */}
  </div>
  {/* /.modal-dialog */}
</div>


    </>
  )
}

export default SetNewPassword
