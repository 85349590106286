import React, { useState, useRef, useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Recaptcha from "react-google-recaptcha";
import applicantContext from "../context/ApplicantContext";
import axios from "axios";

const Signup = (props) => {
  const captchaRef = useRef(null);

  useEffect(() => {
    props.setProgress(25);
    props.setProgress(50);
    props.setProgress(75);
    props.setProgress(100);

  }, []);
  const context = useContext(applicantContext);
  const { showSuccessMessage, showErrorMessage } = context;
 

  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    password: "",
    cpassword: "",
  });
  // below state is to set form errors...
  let history = useNavigate();
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const verifyToken = async (token) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BACKEND_ADDRESS}api/userRoutes/verify-token`,
        {
          secret: process.env.REACT_APP_SECRET_KEY,
          token,
        },
      );
      return response.data;
    } catch (error) {
      console.log("error ", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
   

    const { name, email, password, cpassword } = credentials;
    if (name.length < 4) {
      showErrorMessage("Name cannot be less than 4 Characters", "ERROR");

      document.querySelector("#name").classList.remove("is-valid");
      document.querySelector("#name").classList.add("is-invalid");
      document.querySelector("#name").focus();

      return;
    } else if (email.length < 5) {
      document.querySelector("#name").classList.remove("is-invalid");
      document.querySelector("#name").classList.add("is-valid");

      showErrorMessage("Email cannot be less than 5 Characters", "ERROR");
      document.querySelector("#email").classList.remove("is-valid");
      document.querySelector("#email").classList.add("is-invalid");
      document.querySelector("#email").focus();

      return;
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      showErrorMessage(
        "Email Format is incorrect. Please enter valid Email address",
        "ERROR"
      );
      document.querySelector("#email").classList.remove("is-valid");
      document.querySelector("#email").classList.add("is-invalid");

      return;
    } else if (password.length < 8) {
      document.querySelector("#email").classList.remove("is-invalid");
      document.querySelector("#email").classList.add("is-valid");
      showErrorMessage("Password cannot be less than 8 Characters", "ERROR");

      document.querySelector("#password").classList.remove("is-valid");
      document.querySelector("#password").classList.add("is-invalid");
      document.querySelector("#password").focus();

      return;
    } else if (!(password === cpassword)) {
      showErrorMessage(
        "Password & Confirm Password values must match..",
        "ERROR"
      );

      document.querySelector("#password").classList.remove("is-valid");
      document.querySelector("#password").classList.add("is-invalid");
      document.querySelector("#cpassword").classList.remove("is-valid");
      document.querySelector("#cpassword").classList.add("is-invalid");
      document.querySelector("#password").focus();

      return;
    }


    let token = captchaRef.current.getValue();
    if (token) {
      let valid_token = await verifyToken(token);
      if (valid_token.success) {
        
  

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_ADDRESS}api/userRoutes/createuser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: name,
            email: email,
            password: password,
          }),
        }
      );
    
      if (!response.ok) {
        // Check if response is not OK (status code is not in the range 200-299)
        throw new Error(`Request failed with status ${response.status}`);
      }
    
      const json = await response.json();
    
      if (json.success) {
        showSuccessMessage("Your Account is created. Please enter your email and password to proceed", "Success");
        history("/login");
      } else {
        showErrorMessage(json.error, "ERROR");
      }
    } catch (error) {
      // Handle any errors that occurred during the fetch or parsing the response
      console.error("API request failed:", error);
      // Show an error message to the user or handle the error appropriately
      // For example:
      showErrorMessage("An error occurred while processing your request", "ERROR");
    }
    



      } else {
        showErrorMessage("Sorry!! Token invalid. Please refresh the page and try again", "INVALID");
        return;
      }
    } else {
      showErrorMessage(
        "You must confirm that you are not a robot",
        "CONFIRM IDENTITY"
      );
      return;
    }


  };

  return (
    <>
    
      <div className="container-fluid" >

  <div className="row align-items-start" style={{ background: '#fff', margin:'40px', padding:'20px'}}>
  
  <div className="col-sm-8 my-3" > 
 <center> <h2> <img
  src="/uop-logo-png.png"
  alt="Academic Block"
  width="80px"
/>  University of Peshawar Graduate Admissions 2023</h2></center> 
     
<p style={{margin:'20px', fontSize:'18px', textAlign:'justify'}}> <img
  src="/backCover23.jpeg"
  className="rounded img-fluid"
  alt="Academic Block"
  width="400px"
  style={{margin:'10px', float:'left'}}
/> 
<br/>
<span style={{fontSize:'30px'}}><strong>W</strong></span>elcome to online Admissions Portal of University of Peshawar for the session 2023-2024. To facilitate the applicant, a short video tutorial regarding applying for admissions for different academic programs will be uploaded soon.

</p>
{/* <button
                      type="button"
                      className="btn btn-danger"
                      data-toggle="modal"
                      data-target="#modal-lg"
                    >
                      Click here to Watch Video Tutorial
                    </button> */}
    </div>
  <div className="col-sm-4 my-3" >
  <div className="container">
  <center><Link to="/login" className="btn btn-danger mx-3 my-3">Go Back to Login Page</Link></center>
            <div
              className="card card-outline card-warning"
              style={{ marginTop: "1em important" }}
            >
              <div className="card-header text-center">
                <h3>Signup Now</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      onChange={onChange}
                      value={credentials.name}
                      className="form-control"
                      name="name"
                      id="name"
                      aria-describedby="namehelp"
                      placeholder="Enter Full Name"
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-user "></span>
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      onChange={onChange}
                      value={credentials.email}
                      className="form-control"
                      name="email"
                      id="email"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-envelope"></span>
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="password"
                      value={credentials.password}
                      onChange={onChange}
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder="Enter Password"
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>

                  <div className="input-group mb-3">
                    <input
                      type="password"
                      value={credentials.cpassword}
                      onChange={onChange}
                      className="form-control"
                      id="cpassword"
                      name="cpassword"
                      placeholder="Enter Confirm Password"
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3" style={{maxWidth:'100%'}}>
                    <Recaptcha
                      ref={captchaRef}
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      size="compact"
                    />
                  </div>
                  <div className="row">
                    <div className="col-12"><center>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block text-nowrap"
                      >
                        Register
                      </button></center>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
            </div>
           
          </div>
      </div>

      <div className="modal fade" id="modal-lg">
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">x</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="embed-responsive embed-responsive-16by9">
                        <iframe
    className="embed-responsive-item"
    src={process.env.REACT_APP_VIDEO_TUTORIAL}
    title="Embedded YouTube Video"
    allowFullScreen
  ></iframe>
</div>
                            
                           
                        
                         

                         
                      </div>
                      <div className="modal-footer justify-content-between">
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-dismiss="modal"
                        >
                          Close
                        </button>

                       
                      </div>
                  </div>
                </div>
              </div>
    </>
  );
};

export default Signup;
