import React, { useContext, useRef, useEffect, useState, Suspense, lazy } from "react";
import {json, useNavigate} from "react-router-dom";
import applicantContext from "../context/ApplicantContext";
import AdvertismentApply from "./AdvertismentApply";
import  secureLocalStorage  from  "react-secure-storage";
const DownloadApplicationForm = lazy(() => import('./DownloadApplicationForm'));
const VoucherHandler = lazy(() => import('./VoucherHandler'));


const Home5 = (props) => {
  const refOpen = useRef(null);
  const voucherOpen = useRef(null);
  const downloadOpen = useRef(null);


  let history = useNavigate();
  const context = useContext(applicantContext);
  const {applicant, showErrorMessage, getApplicantProfile, getApplications, applicantApplications, authenticateApplicant} = context;
  const [updateCounter, setUpdateCounter] = useState(0);
  const [activeAds, setActiveAds] = useState([]);
  const [selectedAdvertisment, setSelectedAdvertisment] = useState(null);
  const [advertismentDetails, setAdvertismentDetails] = useState([]);
  const [uniqueDepartments, setUniqueDepartments] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [quotaList, setQuotaList] = useState(null);
  const [selectedQuota, setSelectedQuota] = useState(['Open Quota']); // Initialize with 'Open Quota'
  
  const [studyGroups, setStudyGroups] = useState([]);

  const [applyData, setApplyData] = useState({
    advertismentId:'',
    advertismentName: '',
    advertismentProgramId: '',
    programName: '',
    applyQuota: '',
    hq_status: '',
    consumer_Detail: '',
    Due_Date: '',
    amount: '',
    amount_After_DueDate: '',
    reserved: ''
      });

const handleAdvertismentSelect =  async (event) =>
{

  const selectedValue = parseInt(event.target.value, 10); // Convert to number
  // changed from filter to find...
  const selectedOption = activeAds.find(option => option.advertismentId === selectedValue);
  setSelectedAdvertisment(selectedOption);
  setUniqueDepartments(null);
  setSelectedDepartment(null);
  setSelectedProgram(null);
  setSelectedQuota(['Open Quota']);

  //  await getAdvertismentDetails(selectedValue);
  const response = await fetch(`${process.env.REACT_APP_BACKEND_ADDRESS}api/advertismentRoutes/getAdvertismentDetails`, {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
    },
    body:JSON.stringify({advertismentId: selectedValue })
  });
const json = await response.json();
if(json.success)
{
  setAdvertismentDetails(json.advertismentDetails);
const uniqueDepartmentNames = [...new Set(json.advertismentDetails?.map(detail => detail.departmentName))];
 setUniqueDepartments(uniqueDepartmentNames); // Assuming you have a state for unique departments
}
}
const handleDepartmentSelect = (event) =>{
  const selectedValue = event.target.value;
const selectedDepartment = advertismentDetails.filter(item => item.departmentName === selectedValue);

  setSelectedDepartment(selectedDepartment);
  setSelectedProgram(null);
  setSelectedQuota(['Open Quota']);
  

 }
 const handleProgramSelect = (event) =>{
  const selectedProgramName = event.target.value;
  const selectedProgram = selectedDepartment?.find(program => program.programName === selectedProgramName);
  setSelectedProgram(selectedProgram);
  // const quotaEligibilityArray = selectedProgram.quotaEligibility.split(',');
  const quotaEligibilityArray = selectedProgram?.quotaEligibility?.split(',').map(quota => quota.trim());
setQuotaList(quotaEligibilityArray);
// console.log(quotaEligibilityArray)
setSelectedQuota(['Open Quota']);

setApplyData({...applyData,
  advertismentId: selectedProgram.advertismentId,
  advertismentProgramId: selectedProgram.advertismentProgramId,
  advertismentName: selectedAdvertisment?.advertismentName,
  programName: selectedProgram?.programName,
  hq_status: applicant.hafizQuran,
  consumer_Detail: applicant.name,
  Due_Date : selectedAdvertisment.endDate,
  amount: selectedAdvertisment.amount,
  amount_After_DueDate: selectedAdvertisment.amount_after_DueDate,
  reserved: applicant.contact
})

 }
 const handleQuotaSelect = (event) => {
  const selectedValue = event.target.value;
  setSelectedQuota(prevSelectedQuota => {
    if (!Array.isArray(prevSelectedQuota)) {
      // If it's not an array, handle it accordingly
      return [selectedValue]; // Initialize as an array with the selected value
    }

    if (prevSelectedQuota.includes(selectedValue)) {
      // Remove from array if already selected
      return prevSelectedQuota.filter(item => item !== selectedValue);
    } else {
      // Add to array if not already selected
      return [...prevSelectedQuota, selectedValue];
    }
  });
  
};


 const getActiveAdvertisment = async () =>
 {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_ADDRESS}api/advertismentRoutes/getActiveAdvertisments`, {
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
     // "auth-token": secureLocalStorage.getItem('token'),
    },
  });
const json = await response.json();
if(json.success)
{
  setActiveAds(json.activeAdvertisements);
}
 }
 const getAdvertismentDetails = async (id) =>
 {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_ADDRESS}api/advertismentRoutes/getAdvertismentDetails`, {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
    },
    body:JSON.stringify({advertismentId: id })
  });
const json = await response.json();
if(json.success)
{
  setAdvertismentDetails(json.advertismentDetails);

}


 }
 const getAllStudyGroups = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}api/studyGroupRoutes/getAllStudyGroups`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          //name: `${process.env.REACT_APP_QUALIFICATION}`,
         // name: "HigherStudies",
        }),
      }
    );
    const data = await response.json();
    setStudyGroups(data);
  } catch (error) {
    console.error(error);
  }
};

  useEffect(() => {
    props.setProgress(20);

    authenticateApplicant(secureLocalStorage.getItem("token"))
      .then((isAuthenticated) => {
        if (isAuthenticated) {
          props.setProgress(50);

          getActiveAdvertisment();
          props.setProgress(75);

          getApplicantProfile();
          getAllStudyGroups();
          props.setProgress(100);


        } else {
          secureLocalStorage.clear();

          history("/login");
        }
      });
  }, []);

  useEffect(() => {
      getApplications();
  }, [updateCounter]);
  
 const handleApplyNow = (e) =>{
  e.preventDefault();
  const genderEligibilityArray = selectedProgram?.genderEligibility.split(',');
  setApplyData({
    ...applyData,
    applyQuota: selectedQuota.join(', '), // Converts array to comma-separated string
  });
if(e.target.selectCollege.value ==='')
{
  showErrorMessage("Please Select Advertisment First !","ERROR");
  return false;
}

else if (e.target.selectDepartment.value === '')
{
  showErrorMessage("Please Select Department First !","ERROR");
  return false;
}
else if (e.target.selectProgram.value === '')
{
  showErrorMessage("Please Select Program First !","ERROR");
  return false;
}


  // here we will check the eligibility first....
  const advProgramExists = applicantApplications.some(
    (application) => application.advertismentProgramId === applyData.advertismentProgramId
  );
  
  if (advProgramExists) {
    showErrorMessage("Dear Applicant, you already applied for this Program. Please review","ERROR");
    return false;
  }
  else if (!genderEligibilityArray.includes(applicant.gender)) {
  
    showErrorMessage(`The Required Gender for <b>${selectedProgram?.programName}</b> is <b> ${selectedProgram?.genderEligibility} </b> whereas your Gender is <b>${applicant.gender} </b>. If you have mistakenly selected wrong Gender, please go to "Personal Information" Section and make necessary changes first !`,"ERROR");
    return false;
  }
  // else if (applicant.qualification[0] ===undefined){
  //   showErrorMessage("You have to enter your Qualification First !","ERROR");
  //   return false;
  // }
  
  else if (!selectedProgram?.advProgramGroups.split(",").includes(applicant.studyGroupId.toString())) {
    const requiredStudyGroups = selectedProgram.advProgramGroups.split(",").map((groupId) => {
      const match = studyGroups.find((group) => group.studyGroupId === parseInt(groupId));
      return match ? match.title : "";
    }).join(", ");
  
    const applicantStudyGroup = studyGroups.find((group) => group.studyGroupId === applicant.studyGroupId)?.title || "";
  
    showErrorMessage(`The Required Study Group for 
      <b>${selectedProgram.programName}</b> is <b>${requiredStudyGroups}</b>. 
      Whereas your Study Group in Last Examination was 
      <b>${applicantStudyGroup}</b>. 
      If you have mistakenly Entered Wrong Qualification information, 
      please go to "Academic Qualification" Section and make necessary changes first !`,
      "ERROR");
  
    return false;
  }
  
//   else if (!studyGroupEligibilityArray.includes(applicant.studyGroupId)) {

// // Place the showErrorMessage function call here, after the condition check
//     showErrorMessage(`The Required Study Group for 
//     <b>${selectedProgram.programName}</b> is<b>  ${selectedProgram.advProgramGroups.map((eligibility) => {
//       const matches = studyGroups
//         .map((qualification) => qualification.find((group) => group._id === eligibility))
//         .filter(Boolean);
//       return matches.length > 0 ? matches.map((match) => match.name).join(", ") : "";
//     })}
    
//     </b> 
//     whereas your Study Group in Last Examination was 
//     <b>${studyGroups.map((qualification) => {
//       const match = qualification.studyGroup.find((group) => group._id === applicant.qualification[0].studyGroup);
//       return match ? match.name : "";
//     })}
//      </b>. 
//     If you have mistakenly Entered Wrong Qualification information, 
//     please go to "Academic Qualification" Section and make necessary changes first !`,"ERROR");
//     return false;
//   }
  else if (applicant.gradingSystem==='Marks')
  {
    if((applicant.obtainedMarks*100)/applicant.totalMarks < selectedProgram.marksEligibility)
    {
      const achievedPercentage = ((applicant.obtainedMarks*100)/applicant.totalMarks).toFixed(2);
      showErrorMessage(`The Required Percentage for <b>${selectedProgram.programName}</b> is<b> ${selectedProgram.marksEligibility} </b> whereas your Academic Percentage is <b>${achievedPercentage} % </b>. If you have mistakenly Entered wrong Qualification Marks, please go to "Academic Qualification" Section and make necessary changes first !`,"ERROR");
  
      return false;
  
    }
  } 
  else if (applicant.gradingSystem==='CGPA')
  { 
    if(applicant.obtainedMarks < selectedProgram.cgpaEligibility)
    {
      showErrorMessage(`The Required CGPA for <b>${selectedProgram.programName}</b> is<b> ${selectedProgram.cgpaEligibility} </b> whereas your CGPA is <b>${applicant.obtainedMarks} </b>. If you have mistakenly Entered wrong Qualification Marks, please go to "Academic Qualification" Section and make necessary changes first !`,"ERROR");
  
      return false;
  
    }
    

  }

  // assume everything is as per the requirment.. we can now proceed to reivew the form data to applicant.
  refOpen.current.click();
  


 }
 const [applicationDetails, setApplicationDetails] = useState(null);
 const handleVoucher = (pro) => {
  setApplicationDetails(pro);
voucherOpen.current.click();


 }
 const handleDownloadApplication = (pro) =>{
  setApplicationDetails(pro);
  downloadOpen.current.click();

 }

  return (
    <>
      <div className="content-wrapper" style={{ minHeight: "1046px" }}>
        {/* Content Header (Page header) */}
        <div className="content-header">
          {/* <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
              <h1 className="m-0">Applicant Portal</h1>
             
              </div>
             
            </div>
          </div> */}
        </div>

        <section className="content">
          <div
            className="card card-primary card-outline"
            style={{ minHeight: "466px" }}
          >
             <div className="card-header">
              <h5 className="card-title" style={{fontWeight:'bold'}}>Step 5 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg> Apply for Admission</h5>
            </div>

            <div className="card-body pb-0">
   


{/* <h4>Apply for Admission: </h4> */}
           <div className="small-box bg-warning" >
 <div className="inner" >

<form onSubmit={handleApplyNow}>
                      <div className="card-body">
                      
                        <div className="row">
                          <div className="col-sm-6">
                            
                          <div className="form-group">
                  <label>Select Advertisment</label>
                  <select className="form-control" onChange={handleAdvertismentSelect} id="selectCollege" name="selectCollege" style={{width: "100%"}}>
                    <option value=""> -- Select Advertisment</option>
                    {activeAds!==undefined && activeAds?.map((pro, index) => {
                       return(
                    <option key={index} value={pro.advertismentId}>{pro.advertismentName}</option>
                       )})}
                    
                    
                  </select>
                </div>
                          </div>
                          <div className="col-sm-6">
                           {uniqueDepartments && ( 
                            <div className="form-group">
                    <label>Select Department</label>
                    <select className="form-control" onChange={handleDepartmentSelect} id="selectDepartment" name="selectDepartment" style={{width: "100%"}}>
                      <option value=""> -- Select Department --</option>
                      {uniqueDepartments?.map((department, subIndex) => (
    <option key={subIndex} value={department}>{department}</option>
  ))}
                      
                      
                    </select>
                  </div>)}
                            </div>
</div>
<div className="row">
                          <div className="col-sm-5">
                            
                          {selectedDepartment && (
        <div className="form-group">
          <label>Select Program:</label>
          <select
            id="selectProgram"
            name="selectProgram"
            onChange={handleProgramSelect}
            value={selectedProgram ? selectedProgram.programName : ''}
            className="form-control"
            style={{ width: "100%" }}
          >
            <option value=""> -- Select Program --</option>
            {selectedDepartment?.map((item, subIndex) => (
              <option key={subIndex} value={item.programName}>{item.programName}</option>
            ))}
          </select>
        </div>
      )}



                          </div>
                          <div className="col-sm-4">
                            
                
  {selectedProgram && (
  <div className="form-group">
    <label>Select Quota</label>
    <div className="checkbox-group">
     
     <div className="form-check">
  <input
    type="checkbox"
    id={`checkbox-open-quota`}
    name={`checkbox-open-quota`}
    value="Open Quota"
    onChange={handleQuotaSelect}
    checked={selectedQuota?.includes("Open Quota")}
    disabled={true} // Set the disabled attribute to true for "Open Quota"
    className="form-check-input"
  />
  <label htmlFor={`checkbox-open-quota`} className="form-check-label">
    Open Quota
  </label>
</div>
{/* Render other quota options */}
{quotaList?.map((item, subSubIndex) => (
  <div key={subSubIndex} className="form-check">
    <input
      type="checkbox"
      id={`checkbox-${subSubIndex}`}
      name={`checkbox-${subSubIndex}`}
      value={item}
      onChange={handleQuotaSelect}
      checked={selectedQuota?.includes(item)}
      className="form-check-input"
    />
    <label htmlFor={`checkbox-${subSubIndex}`} className="form-check-label">
      {item}
    </label>
  </div>
))}

    
    </div>
  </div>
)}

  
  
 </div>
                            
<div className="col-sm-3">
                            <button  type="submit" className="btn btn-success btn-lg" style={{margin:'25px'}} > Apply Now </button>
                             <button ref={refOpen} style={{display: 'none'}} type="button" className="btn btn-danger" data-toggle="modal" data-target="#modal-xl"></button>                          </div> 
                        
                       {/* end here the outer .map */}

</div>
                       


                        </div>
                      

                    </form>

                    </div>
</div>  


<hr/>

<h3>Your Applications:    
</h3>
<div className="card-body table-responsive p-0 table-bordered my-4">
  <table className="table table-hover text-nowrap" style={{textAlign: 'center'}}>
  {applicantApplications && applicantApplications.length > 0 ? <thead>
      <tr>
        <th>Form #</th>
        <th>Applied Program <br/>& Quota</th>
        <th>Due Date</th>

        <th>KuickPay <br/>Consumer Number</th>
        <th>Payment <br/> Status</th>
        {/* <th> Download <br/>Application Form </th> */}


      </tr>
    </thead>: (
  <thead>
    <tr>
      <td colSpan="5" style={{color: 'red', fontWeight: 'bold'}}>You have not yet applied for any program</td>
    </tr>
  </thead>
) }
    <tbody>
    {applicantApplications && applicantApplications.length > 0 && applicantApplications.map((pro, index) => {
       return(
                       <tr key={index} >
                        
                        <td>{pro.applicationId}</td>
       
        <td><strong>{pro.programName}</strong> <br/> on<strong> {pro.quotas}</strong></td>
        <td><strong>Amount:</strong> Rs. {pro.Amount_Within_DueDate}/- <br/>
        <strong>Due Date:</strong> {pro.Due_Date && `${pro.Due_Date.slice(6, 8)}-${pro.Due_Date.slice(4, 6)}-${pro.Due_Date.slice(0, 4)}`}

        </td>  
        <td><strong>{pro.consumerNumber}</strong>
       </td>

        <td>
        <span
  className={`badge ${
    pro.Bill_Status === 'U' ? 'bg-danger' :
    pro.Bill_Status === 'P' ? 'bg-success' :
    pro.Bill_Status === 'B' ? 'bg-warning' : ''
  } p-3`}
  style={{ fontSize: '14px' }}
>        {
  pro.Bill_Status === 'U' ? 'Unpaid' :
  pro.Bill_Status === 'P' ? 'Paid Successfully' :
  pro.Bill_Status === 'B' ? 'Blocked' : ''
}
</span>
          
     
        </td>
        {/* <td>
          
          <button
                    type="button"
                    className="btn btn-primary"
                    onClick={()=>{
                      handleVoucher(pro, applicant)
                    }}    
                  >
                    How to Pay Online
                  </button></td> */}
                  {/* <td >
          
          <button
                    type="button"
                    className="btn btn-success"
                    onClick={()=>{
                      handleDownloadApplication(pro)
                    }}    
                  >
                    View Form
                  </button></td> */}
       

      </tr>

                       )
              })}
              </tbody>
  </table>
</div>
<div className="container">
<h4>Please Note That:</h4>
<ul>
  <li>Only online Payment method is available for depositing the Application Fee.<button  ref={voucherOpen} type="button" className="btn btn-primary" data-toggle="modal" data-target="#pdfhandler">View Online Payment Procedure</button>
</li>
<li>If the <strong>Payment Status</strong> is shown as <span   className="badge bg-danger p-1"><strong>Unpaid</strong></span>, it means you have not yet deposited Admission Fee. Please deposit the prescribed fee before or on Due Date else you will not be considered in the candidates list.</li>

<li>If the <strong>Payment Status</strong> is shown as <span className="badge bg-success p-1"><strong>Paid Successfully</strong></span>, it means your online payment is successful.</li>
<li>If the <strong>Payment Status</strong> is shown as <span className="badge bg-warning p-1"><strong>Blocked</strong></span>, it means due date of online payment is over and you cannot be considered in the candidates list</li>

</ul>
</div>
<button style={{display: 'none'}} ref={downloadOpen} type="button" className="btn btn-warning" data-toggle="modal" data-target="#downloadhandler"></button>
<Suspense fallback={<div>Loading...</div>}>
<VoucherHandler applicationDetails={applicationDetails} />
<DownloadApplicationForm applicationDetails={applicationDetails} applicant={applicant} />
<AdvertismentApply setUpdateCounter={setUpdateCounter} updateCounter={updateCounter} applyData={applyData} applicant={applicant} />

</Suspense>



                        
                      </div>
                      <div className="card-footer">
                      <div className="btn-group float-right" role="group" aria-label="Buttons">
    <button type="button" className="btn btn-warning btn-flat" onClick={()=>{
                              history("/4");
                            }} >Previous</button>

  {/* <button type="button" className="btn btn-warning btn-flat">Save Changes</button> */}
</div>

<div>

  </div>


</div>
         
         
          </div>
        </section>
      </div>
      
    </>
  );
};

export default Home5;
