import React, { useContext, useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import applicantContext from "../context/ApplicantContext";
import secureLocalStorage from "react-secure-storage";

const Home3 = (props) => {
  const context = useContext(applicantContext);
  const {
    showSuccessMessage,
    showErrorMessage,
    authenticateApplicant,
    applicantTest,
    getApplicantTest,
    deleteTest
  } = context;

  let history = useNavigate();
  const refClose = useRef(null);

  const [updateCounter, setUpdateCounter] = useState(0);
  const [test, setTest] = useState([]);
  const [allTests, setAllTests] = useState([]);
  const getAllTests = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_ADDRESS}api/applicantRoutes/getAllTests`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          // body: JSON.stringify({
          //   name: "HigherStudies",
          // }),
        }
      );
      const data = await response.json();
      setAllTests(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    props.setProgress(25);

    // verify if the user is logged in or not.. if no, redirect to login page....
    authenticateApplicant().then((isAuthenticated) => {
      if (isAuthenticated) {
        props.setProgress(50);

        getApplicantTest();
        getAllTests();
        props.setProgress(100);
      } else {
        secureLocalStorage.clear();
        history("/login");
      }
    });
  }, [updateCounter]);
  const formInitialState = {
    testId: "",
    title: "",
    passingDate: "",
    expiryDate: "",
    rollNo: "",
    obtainedMarks: "",
    totalMarks: "",
  };
  const [applicant, setApplicant] = useState(formInitialState);
  const [disableButton, setDisableButton] = useState(false);

  const onChange = (e) => {
    setApplicant({ ...applicant, [e.target.name]: e.target.value });
  };

  const nextStep = async () => {
    // updating the step2 true status....
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}api/userRoutes/updateField`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": secureLocalStorage.getItem("token"),
        },
        body: JSON.stringify({ fieldName: "step4", fieldValue: "true" }),
      }
    );
    const str = await response.json();
    if (str.success) secureLocalStorage.setItem("step4", "true");
    history("/4");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dateRegex = /^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-(\d{4})$/;
  
    if (applicant.testId.length < 1) {
      showErrorMessage("Select Test Type", "Error");
      document.querySelector("#testId").classList.remove("is-valid");
      document.querySelector("#testId").classList.add("is-invalid");
      document.querySelector("#testId").focus();

      return;
    } else if (applicant.title.length < 1) {
      document.querySelector("#testId").classList.remove("is-valid");
      document.querySelector("#testId").classList.add("is-invalid");

      showErrorMessage("Provide Test Title", "Error");

      document.querySelector("#title").classList.remove("is-valid");
      document.querySelector("#title").classList.add("is-invalid");
      document.querySelector("#title").focus();

      return;
    } else if ( !dateRegex.test(applicant.passingDate)) {
        document.querySelector("#title").classList.remove("is-invalid");
        document.querySelector("#title").classList.add("is-valid");
        
        showErrorMessage("Passing Date Must be in the format DD-MM-YYYY","Error");
  
        document.querySelector("#passingDate").classList.remove("is-valid");
        document.querySelector("#passingDate").classList.add("is-invalid");
        document.querySelector("#passingDate").focus();
  
        
        return;
      } else if ( !dateRegex.test(applicant.expiryDate)) {
        document.querySelector("#passingDate").classList.remove("is-invalid");
        document.querySelector("#passingDate").classList.add("is-valid");
        
        showErrorMessage("Expiry Date Must be in the format DD-MM-YYYY","Error");
  
        document.querySelector("#expiryDate").classList.remove("is-valid");
        document.querySelector("#expiryDate").classList.add("is-invalid");
        document.querySelector("#expiryDate").focus();
  
        
        return;
      }else if (applicant.rollNo.length < 1) {
      document.querySelector("#expiryDate").classList.remove("is-invalid");
      document.querySelector("#expiryDate").classList.add("is-valid");
      showErrorMessage("Enter your Roll No", "Error");

      document.querySelector("#rollNo").classList.remove("is-valid");
      document.querySelector("#rollNo").classList.add("is-invalid");
      document.querySelector("#rollNo").focus();

      return;
    } else if (applicant.obtainedMarks.length < 1 || !/^\d+(\.\d+)?$/.test(applicant.obtainedMarks)) {
      document.querySelector("#rollNo").classList.remove("is-invalid");
      document.querySelector("#rollNo").classList.add("is-valid");
      showErrorMessage("Enter a valid numeric value for Obtained Marks", "Error");

      document.querySelector("#obtainedMarks").classList.remove("is-valid");
      document.querySelector("#obtainedMarks").classList.add("is-invalid");
      document.querySelector("#obtainedMarks").focus();

      return;
    } else if (applicant.totalMarks.length < 1 || !/^\d+(\.\d+)?$/.test(applicant.totalMarks)) {
      document.querySelector("#obtainedMarks").classList.remove("is-invalid");
      document.querySelector("#obtainedMarks").classList.add("is-valid");
      showErrorMessage("Enter a valid numeric value for Total Marks", "Error");

      document.querySelector("#totalMarks").classList.remove("is-valid");
      document.querySelector("#totalMarks").classList.add("is-invalid");
      document.querySelector("#totalMarks").focus();

      return;
    } 
    else if (parseFloat(applicant.obtainedMarks) > parseFloat(applicant.totalMarks)) {
      document.querySelector("#totalMarks").classList.remove("is-invalid");
      document.querySelector("#totalMarks").classList.add("is-valid");
      showErrorMessage("Obtained Marks cannot be greater than Total Marks", "Error");

      document.querySelector("#obtainedMarks").classList.remove("is-valid");
      document.querySelector("#obtainedMarks").classList.add("is-invalid");
      document.querySelector("#obtainedMarks").focus();

      return;
    }
    setDisableButton(true);

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}api/applicantRoutes/addTest`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": secureLocalStorage.getItem("token"),
        },
        body: JSON.stringify(applicant),
      }
    );
    const json = await response.json();
    if (json.success) {
      setApplicant(formInitialState);
      setDisableButton(false);

      refClose.current.click();
      setUpdateCounter(updateCounter + 1);
      showSuccessMessage("Test details Added Successfully !", "SUCCESS");
    } else {
      setDisableButton(false);

      showErrorMessage(json.error, "ERROR");
    }
  };

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "500px" }}>
        {/* Content Header (Page header) */}
        <div className="content-header">
          {/* <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Applicant Portal</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right"></ol>
              </div>
            </div>
          </div> */}
        </div>

        <section className="content">
          <div
            className="card card-primary card-outline"
            style={{ minHeight: "466px" }}
          >
          <div className="card-header">
              <h5 className="card-title" style={{fontWeight:'bold'}}>Step 3 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg> Entry Test Details</h5>
            </div>
            <div className="card-body pb-0">
              <h4>Note:</h4><p style={{color:'red', fontWeight:'bold'}}>This is an optional Step. If you already have qualified the Entry test, you should enter valid test details here. Else, leave this section blank.</p>

              <div>
                <center>
                  {secureLocalStorage.getItem("lockProfile") === "false" ? (
                    <button
                      disabled={
                        applicantTest !== undefined &&
                        applicantTest.length > 0
                          ? "disabled"
                          : ""
                      }
                      type="button"
                      className="btn btn-success"
                      data-toggle="modal"
                      data-target="#modal-lg"
                    >
                      Add Test Details
                    </button>
                  ) : (
                    ""
                  )}
                </center>
              </div>
              {/* The Added qualification will be shown below in tabular format...... */}
              {applicantTest !== undefined &&
                applicantTest.length > 0 && (
                  <div className="card-body table-responsive p-0 table-bordered my-4">
                    <table
                      className="table table-hover text-nowrap"
                      style={{ textAlign: "center" }}
                    >
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Passing <br/>Date</th>
                          <th>Expiry</th>
                          <th>Roll No.</th>
                          <th>Obtained <br/> Marks</th>
                          <th>Total</th>
                          <th>Action</th>

                        </tr>
                      </thead>
                      <tbody>
                        {applicantTest &&
                          applicantTest.map(
                            (pro, index) => (
                              <tr key={index}>
                                <td>{pro.title}</td>
                                <td>{pro.passingDate}</td>
                                <td>{pro.expiryDate}</td>
                                <td>{pro.rollNo}</td>
                                <td>{pro.obtainedMarks}</td>
                                <td>{pro.totalMarks}</td>
                                <td> {secureLocalStorage.getItem("lockProfile") ===
                                  "false" ? (
                                    <i
                                      className="fa-solid fa-trash mx-2"
                                      onClick={() => {
                                        deleteTest(
                                          pro.applicantTestId,
                                          setUpdateCounter,
                                          updateCounter
                                        );
                                      }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                )}

              <div className="modal fade" id="modal-lg">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <form onSubmit={handleSubmit}>
                      <div className="modal-header">
                        <h4 className="modal-title">Add Entrance Test Details (Optional)

                        </h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={refClose}
                        >
                          <span aria-hidden="true">x</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label>Test Type: <span style={{ color:'red' }} >*</span></label>
                                <select
                                  className="form-control "
                                  onChange={onChange}
                                  value={applicant.testId}
                                  id="testId"
                                  name="testId"
                                  style={{ width: "100%" }}
                                >
                                  <option> -- Select Test Type -- </option>
                                  {allTests &&
  allTests.map((pro, ind) =>
        <option key={ind} value={pro.testId}>
         {pro.testName}
        </option>
      
  )}


                                </select>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <label>Title: <span style={{ color:'red' }} >*</span></label>
                                <input type="text"
                                className="form-control"
                                id="title"
                                onChange={onChange}
                                value={applicant.title}
                                name="title"
                                placeholder="Enter Test Title"
                              />
                              </div>
                            </div>
                            <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="cname">Passing Date (DD-MM-YYYY): <span style={{ color:'red' }} >*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                id="passingDate"
                                name="passingDate"
                                onChange={onChange}
                                placeholder="i.e. 15-04-2000"
                                value={applicant.passingDate}
                              />
                            </div>
                          </div>
                         
                          </div>
                          
                      

                          <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="cname">Expiry Date (DD-MM-YYYY): <span style={{ color:'red' }} >*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                id="expiryDate"
                                name="expiryDate"
                                onChange={onChange}
                                placeholder="i.e. 15-04-2000"
                                value={applicant.expiryDate}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4">
                              <label htmlFor="cname">Roll No/ Registration No: <span style={{ color:'red' }} >*</span> </label>
                              <input
                                type="text"
                                className="form-control"
                                id="rollNo"
                                onChange={onChange}
                                value={applicant.rollNo}
                                name="rollNo"
                                placeholder="Enter Roll No."
                              />
                            </div>
                            <div className="col-sm-4">
                              <label htmlFor="cname">Obtained Marks: <span style={{ color:'red' }} >*</span> </label>
                              <input
                                type="text"
                                className="form-control"
                                id="obtainedMarks"
                                onChange={onChange}
                                value={applicant.obtainedMarks}
                                name="obtainedMarks"
                                placeholder="Obtained Marks"
                              />
                            </div>
                          
                          </div>
                          <div className="row">
                          <div className="col-sm-4">
                              <label htmlFor="cname">Total Marks: <span style={{ color:'red' }} >*</span> </label>
                              <input
                                type="text"
                                className="form-control"
                                id="totalMarks"
                                onChange={onChange}
                                value={applicant.totalMarks}
                                name="totalMarks"
                                placeholder="Enter Total Marks"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer justify-content-between">
                        <button
                          type="button"
                          className="btn btn-default"
                          data-dismiss="modal"
                        >
                          Close
                        </button>

                        <button
                          type="submit"
                          name="submit"
                          id="submit"
                          className="btn btn-primary"
                          disabled={
                            disableButton
                              ? "disabled"
                              : ""
                          }  
                        >
                          Add Test
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div
                className="btn-group float-right"
                role="group"
                aria-label="Buttons"
              >
                <button
                  type="button"
                  className="btn btn-warning btn-flat"
                  onClick={() => {
                    history("/2");
                  }}
                >
                  Previous
                </button>
                {secureLocalStorage.getItem("lockProfile") === "false" ? (
                  <button
                    type="button"
                    className="btn btn-warning btn-flat"
                   
                    onClick={nextStep}
                  >
                    Save & Next
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home3;
