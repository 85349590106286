import React, {useContext} from 'react'
import {Link} from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import applicantContext from "../context/ApplicantContext";
import  secureLocalStorage  from  "react-secure-storage";


const Navbar = (props) => {
  const context = useContext(applicantContext);
  const {showSuccessMessage,  showErrorMessage} = context;
  let history = useNavigate();
  const handleLogout = ()=>{
    props.setIsLoggedIn(false);

    secureLocalStorage.clear();

    showSuccessMessage("You have Successfully logged out. Please Login again to continue..","Success");

    history("/login");

}

  return (
    <>
<nav className="main-header navbar navbar-expand-md navbar-light navbar-white">
 
  <ul className="navbar-nav">
  <li className="nav-item">
        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
      </li>
   
    
    <li className="nav-item d-none d-sm-inline-block">
      <a className="nav-link" style={{color:'#000', fontSize:'20px'}} >Online Admissions Portal, University of Peshawar</a>
    </li>
  </ul>
  <ul className="navbar-nav ml-auto">
  {!secureLocalStorage.getItem('token')? <form className='d-flex' >
  <li className="nav-item"><Link className="btn btn-warning mx-1" to="/login" role="button">Login</Link></li>
  <li className="nav-item"><Link className="btn btn-warning mx-1" to="/signup" role="button">Signup</Link></li>

   </form>:<li className="nav-item">You are Logged-in as <strong>{secureLocalStorage.getItem("email")} </strong><button className='btn btn-warning' onClick={handleLogout} >Logout</button></li>}
    </ul>
  
    
</nav>

</>


  )
}

export default Navbar
