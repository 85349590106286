import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import applicantContext from "../context/ApplicantContext";
import secureLocalStorage from "react-secure-storage";
const Login = (props) => {
  const getActiveAdvertisment = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_ADDRESS}api/advertismentRoutes/getActiveAdvertisments`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
       // "auth-token": secureLocalStorage.getItem('token'),
      },
    });
    // now fetching below...
    const json = await response.json();
    // showing on console......
    if (json.success) {
      setActiveAds(json.activeAdvertisements);

    }
  }

useEffect(() => {
    props.setProgress(25);
    props.setProgress(50);
    getActiveAdvertisment();
    props.setProgress(75);
    props.setProgress(100);

  }, []);
  const [activeAds, setActiveAds] = useState([]);
  const context = useContext(applicantContext);
  const { showSuccessMessage, showErrorMessage } = context;

  const [validateForm, setValidateForm] = useState({});

  const [credentials, setCredentials] = useState({ email: "", password: "" });
  let history = useNavigate();

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // For validation...
    const { name, email, password, cpassword } = credentials;

    if (email.length < 5) {
      showErrorMessage("Enter Email Address First", "ERROR");
      document.querySelector("#email").classList.remove("is-valid");
      document.querySelector("#email").classList.add("is-invalid");

      return;
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      showErrorMessage(
        "Email Format is incorrect. Please Enter valid Email Address",
        "ERROR"
      );
      document.querySelector("#email").classList.remove("is-valid");
      document.querySelector("#email").classList.add("is-invalid");

      return;
    } else if (password.length < 8) {
      document.querySelector("#email").classList.remove("is-invalid");
      document.querySelector("#email").classList.add("is-valid");
      showErrorMessage("Password Must be Atleast 8 Characters Long", "ERROR");
      document.querySelector("#password").classList.remove("is-valid");
      document.querySelector("#password").classList.add("is-invalid");

      return;
    }
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}api/userRoutes/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: credentials.email,
          password: credentials.password,
        }),
      }
    );
    
    if (!response.ok) {
      try {
        const errorResponse = await response.json();
        const errorMessage = errorResponse.error;
        showErrorMessage(errorMessage, "ERROR");
        throw new Error('Request failed: ' + response.status + ' - ' + errorMessage);
      } catch (error) {
        console.error('An error occurred:', error);
      }
    } else {
      try {
        const json = await response.json();
        if (json.success) {
          // Save the Auth Token and Redirect...
          secureLocalStorage.setItem("token", json.authToken);
          secureLocalStorage.setItem("email", json.email);
         props.setIsLoggedIn(true);
          secureLocalStorage.setItem("lockProfile",JSON.stringify(json.lockProfile));
          secureLocalStorage.setItem("step1", JSON.stringify(json.step1));
          secureLocalStorage.setItem("step2", JSON.stringify(json.step2));
          secureLocalStorage.setItem("step3", JSON.stringify(json.step3));
          secureLocalStorage.setItem("step4", JSON.stringify(json.step4));
          secureLocalStorage.setItem("step5", JSON.stringify(json.step5));
          // Navigate to a specific page after successful login and token authentication...
          showSuccessMessage("You have Successfully logged In.", "Success");
    
         history("/1");
        } else {
          showErrorMessage(json.error, "Error");
          return false;
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
    
  };

  return (
    <>
 <div className="container-fluid" >

  <div className="row align-items-start" style={{ background: '#fff', margin:'40px', padding:'20px'}}>
  
  <div className="col-sm-8 my-3" >
 <center> <h2> <img
  src="/uop-logo-png.png"
  alt="Academic Block"
  width="80px"
/>  University of Peshawar Admissions Portal</h2>
</center> 
     
<p style={{margin:'20px', fontSize:'18px', textAlign:'justify'}}> <img
  src="/backCover23.jpeg"
  className="rounded img-fluid"
  alt="Academic Block"
  width="400px"
  style={{margin:'10px', float:'left'}}
/> 
<br/>
<span style={{fontSize:'30px'}}><strong>W</strong></span>elcome to online Admissions Portal of University of Peshawar for Graduate Admissions. To facilitate the 
applicant, a short video tutorial regarding applying for admissions for different academic programs will be uploaded soon.</p>
{/* <button
                      type="button"
                      className="btn btn-danger"
                      data-toggle="modal"
                      data-target="#modal-lg"
                    >
                      Click here to Watch Video Tutorial
                    </button> */}
                    <br/><br/>
                    {activeAds !== undefined && activeAds.length > 0 &&
  <>
    <hr className="hr hr-blurry" style={{ marginTop: '30px' }} />
    <blockquote>
      <h5>Note:</h5>
      <strong>The following Admissions of the University are currently open:</strong>
    </blockquote>
    <div className="row">
      {activeAds.map((pro, index) => {
        return (
          <div className="card bg-warning mx-2" style={{ minWidth: '150px', maxWidth: '500px' }} key={index}>
            <div className="card-header"><strong>{pro.advertismentName}</strong></div>
            <div className="card-body">
              <p className="card-text">{pro.description} </p>
            </div>
          </div>
        )
      })}
    </div>
  </>
}


    </div>

  <div className="col-sm-4 my-3" >
                <div
              className="card card-outline card-warning"
              style={{ maxWidth: "400px", float:'right', marginTop:'45px' }}
            >
              <div className="card-header text-center card-warning">
                <h3>
                 <b>Applicant Login</b>
                </h3>
              </div>
              <div className="card-body">
                <p className="login-box-msg">
                  Please Enter your Email address and Password to login
                </p>
                <form method="post" onSubmit={handleSubmit}>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      onChange={onChange}
                      id="email"
                      value={credentials.email}
                      name="email"
                      placeholder="Enter email"
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-envelope" />
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="password"
                      className="form-control"
                      onChange={onChange}
                      id="password"
                      value={credentials.password}
                      name="password"
                      placeholder="Password"
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      {/* <div className="icheck-primary">
                                    <input type="checkbox" id="remember" />
                                    <label htmlFor="remember">Remember Me</label>
                                </div> */}
                    </div>
                    {/* /.col */}
                    <div className="col-4">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block text-nowrap"
                      >Sign In</button>
                    </div>
                    {/* /.col */}
                  </div>
                </form>

                {/* /.social-auth-links */}
                <p className="mb-1" style={{ marginTop: "20px" }}>
                  <Link to="/forgotPassword" className="btn btn-danger text-nowrap">
                    Forgot Password? Click here
                  </Link>
                </p>
                <p className="mb-0">
                  <Link to="/signup" className=" btn btn-success text-nowrap">
                    {" "}
                    No Account yet? Signup here{" "}
                  </Link>
                </p>
              </div>
            </div>
            </div>
           
          </div>
      </div>
       <div className="modal fade" id="modal-lg">
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">x</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="embed-responsive embed-responsive-16by9">
  <iframe
    className="embed-responsive-item"
    src={process.env.REACT_APP_VIDEO_TUTORIAL}
    title="Embedded YouTube Video"
    allowFullScreen
  ></iframe>
</div>
                            
                           
                        
                         

                         
                      </div>
                      <div className="modal-footer justify-content-between">
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-dismiss="modal"
                        >
                          Close
                        </button>

                       
                      </div>
                  </div>
                </div>
              </div>
    </>
  );
};

export default Login;
