import React, { useContext, useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import applicantContext from "../context/ApplicantContext";
import secureLocalStorage from "react-secure-storage";

const Home2 = (props) => {
  const context = useContext(applicantContext);
  const {
    applicantQualification,
    showSuccessMessage,
    showErrorMessage,
    setApplicantQualification,
    getApplicantQualification,
    deleteQualification,
    authenticateApplicant,
  } = context;

  let history = useNavigate();
  const refClose = useRef(null);

  const [updateCounter, setUpdateCounter] = useState(0);
  const [qualifications, setQualifications] = useState([]);
  const getAllStudyGroups = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_ADDRESS}api/studyGroupRoutes/getAllStudyGroups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          // body: JSON.stringify({
          //   name: "HigherStudies",
          // }),
        }
      );
      const data = await response.json();
      setQualifications(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    props.setProgress(25);

    getAllStudyGroups();
    // verify if the user is logged in or not.. if no, redirect to login page....
    authenticateApplicant().then((isAuthenticated) => {
      if (isAuthenticated) {
        props.setProgress(50);

        getApplicantQualification();
        props.setProgress(100);
      } else {
        secureLocalStorage.clear();
        history("/login");
      }
    });
  }, [updateCounter]);
  const formInitialState = {
    degreeTitle: "",
    degreeFrom: "",
    studyGroupId: "",
    gradingSystem: "",
    passingYear: "",
    rollNo: "",
    obtainedMarks: "",
    totalMarks: "",
  };
  const [applicant, setApplicant] = useState(formInitialState);
  const [disableButton, setDisableButton] = useState(false);
  const onChange = (e) => {
    setApplicant({ ...applicant, [e.target.name]: e.target.value });
  };

  const nextStep = async () => {
    // updating the step2 true status....
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}api/userRoutes/updateField`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": secureLocalStorage.getItem("token"),
        },
        body: JSON.stringify({ fieldName: "step3", fieldValue: "true" }),
      }
    );
    const str = await response.json();
    if (str.success) secureLocalStorage.setItem("step3", "true");
    history("/3");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (applicant.studyGroupId.length < 1) {
      showErrorMessage("Select Qualification Level", "Error");

      document.querySelector("#studyGroupId").classList.remove("is-valid");
      document.querySelector("#studyGroupId").classList.add("is-invalid");
      document.querySelector("#studyGroupId").focus();

      return;
    } else if (applicant.degreeTitle.length < 3) {
      document.querySelector("#studyGroupId").classList.remove("is-invalid");
      document.querySelector("#studyGroupId").classList.add("is-valid");
      showErrorMessage("Provide your Degree Title", "Error");
      document.querySelector("#degreeTitle").classList.remove("is-valid");
      document.querySelector("#degreeTitle").classList.add("is-invalid");
      document.querySelector("#degreeTitle").focus();

      return;
    } else if (applicant.studyGroupId.length < 1) {
      document.querySelector("#degreeTitle").classList.remove("is-invalid");
      document.querySelector("#degreeTitle").classList.add("is-valid");
      showErrorMessage("Select Study Group", "Error");

      document.querySelector("#studyGroupId").classList.remove("is-valid");
      document.querySelector("#studyGroupId").classList.add("is-invalid");
      document.querySelector("#studyGroupId").focus();

      return;
    } else if (applicant.degreeFrom.length < 3) {
      document.querySelector("#studyGroupId").classList.remove("is-invalid");
      document.querySelector("#studyGroupId").classList.add("is-valid");
      showErrorMessage("Please enter your University/ Board", "Error");

      document.querySelector("#degreeFrom").classList.remove("is-valid");
      document.querySelector("#degreeFrom").classList.add("is-invalid");
      document.querySelector("#degreeFrom").focus();

      return;
    } else if (applicant.gradingSystem.length < 2) {
      document.querySelector("#degreeFrom").classList.remove("is-invalid");
      document.querySelector("#degreeFrom").classList.add("is-valid");
      showErrorMessage("Select Grading System", "Error");

      document.querySelector("#gradingSystem").classList.remove("is-valid");
      document.querySelector("#gradingSystem").classList.add("is-invalid");
      document.querySelector("#gradingSystem").focus();

      return;
    } else if (applicant.passingYear.length < 4) {
      document.querySelector("#gradingSystem").classList.remove("is-invalid");
      document.querySelector("#gradingSystem").classList.add("is-valid");
      showErrorMessage("Select your Year of Passing Examination", "Error");

      document.querySelector("#passingYear").classList.remove("is-valid");
      document.querySelector("#passingYear").classList.add("is-invalid");
      document.querySelector("#passingYear").focus();

      return;
    } else if (applicant.rollNo.length < 1) {
      document.querySelector("#passingYear").classList.remove("is-invalid");
      document.querySelector("#passingYear").classList.add("is-valid");
      showErrorMessage("Enter your Roll No", "Error");

      document.querySelector("#rollNo").classList.remove("is-valid");
      document.querySelector("#rollNo").classList.add("is-invalid");
      document.querySelector("#rollNo").focus();

      return;
    } 
   
    else if (applicant.obtainedMarks.length < 1 || !/^\d+(\.\d+)?$/.test(applicant.obtainedMarks)) {
      document.querySelector("#rollNo").classList.remove("is-invalid");
      document.querySelector("#rollNo").classList.add("is-valid");
      
      showErrorMessage("Enter a valid numeric value for Obtained Marks", "Error");
    
      document.querySelector("#obtainedMarks").classList.remove("is-valid");
      document.querySelector("#obtainedMarks").classList.add("is-invalid");
      document.querySelector("#obtainedMarks").focus();
    
      return;
    }
    
    else if (applicant.totalMarks.length < 1 || !/^\d+(\.\d+)?$/.test(applicant.totalMarks)) {
      document.querySelector("#obtainedMarks").classList.remove("is-invalid");
      document.querySelector("#obtainedMarks").classList.add("is-valid");
      showErrorMessage("Enter a valid numeric value for Total Marks", "Error");

      document.querySelector("#totalMarks").classList.remove("is-valid");
      document.querySelector("#totalMarks").classList.add("is-invalid");
      document.querySelector("#totalMarks").focus();

      return;
    }
    else if (parseFloat(applicant.obtainedMarks) > parseFloat(applicant.totalMarks)) {
      document.querySelector("#totalMarks").classList.remove("is-invalid");
      document.querySelector("#totalMarks").classList.add("is-valid");
      showErrorMessage("Obtained Marks cannot be greater than Total Marks", "Error");

      document.querySelector("#obtainedMarks").classList.remove("is-valid");
      document.querySelector("#obtainedMarks").classList.add("is-invalid");
      document.querySelector("#obtainedMarks").focus();

      return;
    }
setDisableButton(true);

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}api/applicantRoutes/addQualification`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": secureLocalStorage.getItem("token"),
        },
        body: JSON.stringify(applicant),
      }
    );
    const json = await response.json();
    if (json.success) {
      setApplicant(formInitialState);
      refClose.current.click();
      setDisableButton(false);

      setUpdateCounter(updateCounter + 1);
      showSuccessMessage("Qualification Added Successfully !", "SUCCESS");
    } else {
      setDisableButton(false);

      showErrorMessage(json.error, "ERROR");
    }
  };

  return (
    <div>
      <div className="content-wrapper" style={{ minHeight: "500px" }}>
        {/* Content Header (Page header) */}
        <div className="content-header">
          {/* <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Applicant Portal</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right"></ol>
              </div>
            </div>
          </div> */}
        </div>

        <section className="content">
          <div
            className="card card-primary card-outline"
            style={{ minHeight: "466px" }}
          >
            <div className="card-header">
              <h5 className="card-title" style={{fontWeight:'bold'}}>Step 2 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg> Academic Qualification Section</h5>
            </div>

            <div className="card-body pb-0">
              <p style={{fontWeight:'bold'}}>Dear Applicant, please enter your last examination information only.</p>




              <div>
                <center>
                  {secureLocalStorage.getItem("lockProfile") === "false" ? (
                    <button
                      disabled={
                        applicantQualification !== undefined &&
                        applicantQualification.length > 0
                          ? "disabled"
                          : ""
                      }
                      type="button"
                      className="btn btn-success"
                      data-toggle="modal"
                      data-target="#modal-lg"
                    >
                      Add Qualification
                    </button>
                  ) : (
                    ""
                  )}
                </center>
              </div>
              {/* The Added qualification will be shown below in tabular format...... */}
              {applicantQualification !== undefined &&
                applicantQualification.length > 0 && (
                  <div className="card-body table-responsive p-0 table-bordered my-4">
                    <table
                      className="table table-hover text-nowrap"
                      style={{ textAlign: "center" }}
                    >
                      <thead>
                        <tr>
                          <th>Degree Details</th>
                          <th>Grading System</th>
                          <th>Marks/ CGPA</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {applicantQualification &&
                          applicantQualification.map(
                            (pro, index) => (
                              <tr key={index}>
                                <td>
                                  <strong>
                                    {pro.degreeTitle}  
                                    
                                    
                                    [
          {qualifications.map((qualification) => {
            const match = qualification.studyGroupId === pro.studyGroupId;
            return match ? qualification.title : "";
          })}
          ]
                                    
                                    </strong>
                                  <br /> from <br />
                                  <strong>
                                    {pro.board}</strong> in the Year <strong>{pro.passingYear}
                                  </strong>
                                </td>
                                <td>
                                  {pro.gradingSystem} <br />
                                </td>
                                <td>
                                  <strong>{pro.obtainedMarks}</strong> out of{" "}
                                  <strong>{pro.totalMarks}</strong> <br />
                                  {(
                                    (pro.obtainedMarks * 100) /
                                    pro.totalMarks
                                  ).toFixed(2) + " %"}
                                </td>
                                <td>
                                  {secureLocalStorage.getItem("lockProfile") ===
                                  "false" ? (
                                    <i
                                      className="fa-solid fa-trash mx-2"
                                      onClick={() => {
                                        deleteQualification(
                                          pro.ApplicantQualificationId,
                                          setUpdateCounter,
                                          updateCounter
                                        );
                                      }}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                )}

              <div className="modal fade" id="modal-lg">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <form onSubmit={handleSubmit}>
                      <div className="modal-header">
                        <h4 className="modal-title">Add Qualification Form</h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={refClose}
                        >
                          <span aria-hidden="true">x</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="card-body">
                        <p style={{fontWeight:'bold', color:'red'}}>Please enter your Study Group Carefully. The eligibility of different programs are dependant over it.</p>
                          <p style={{fontFamily: 'Nafees Naskh', fontWeight:'bold', color:'red', textAlign:'right'}}>برائے مھربانی اپنی بی ایس ، ماسٹر یا ایم فل یا مساوی تعلیم کی تفصیل توجہ سے فراھم کریں۔ مختلف پروگرام کی اھلیت آپ کی فراھم کردہ معلومات پر منحصر ہے۔۔ </p>

                          <div className="row">
                          <div className="col-sm-6">
                              <div className="form-group">
                                <label>Qualification Level: <span style={{ color:'red' }} >*</span></label>
                                <select
                                  onChange={onChange}
                                  value={applicant.studyGroupId}
                                  id="studyGroupId"
                                  name="studyGroupId"
                                  className="form-control"
                                  style={{ width: "100%" }}
                                >
                                  <option> -- Select your Existing Qualification --</option>
                                  {qualifications &&
  qualifications.map((pro, ind) =>
        <option key={ind} value={pro.studyGroupId}>
         {pro.title}
        </option>
      
  )}

                                </select>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Degree Title: <span style={{ color:'red' }} >*</span></label>
                           
                                 <input
                                  onChange={onChange}
                                  value={applicant.degreeTitle}
                                  id="degreeTitle"
                                  name="degreeTitle"
                                  className="form-control"
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                           
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label> University Name: <span style={{ color:'red' }} >*</span></label>
                                <input
                                  onChange={onChange}
                                  value={applicant.degreeFrom}
                                  id="degreeFrom"
                                  name="degreeFrom"
                                  className="form-control"
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Grading System: <span style={{ color:'red' }} >*</span></label>
                                <select
                                  onChange={onChange}
                                  id="gradingSystem"
                                  name="gradingSystem"
                                  value={applicant.gradingSystem}
                                  className="form-control"
                                  style={{ width: "100%" }}
                                >
                                  <option> Select Grading Type</option>
                                  <option value="Marks">Marks</option>
                                  <option value="CGPA">
                                    CGPA
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label>Year of Passing Last Examination: <span style={{ color:'red' }} >*</span></label>
                                <select
                                  onChange={onChange}
                                  value={applicant.passingYear}
                                  id="passingYear"
                                  name="passingYear"
                                  className="form-control"
                                  style={{ width: "100%" }}
                                >
                                  <option> Select Year</option>
                                  <option>2023</option>
                                  <option>2022</option>
                                  <option>2021</option>
                                  <option>2020</option>
                                  <option>2019</option>
                                  <option>2018</option>
                                  <option>2017</option>
                                  <option>2016</option>
                                  <option>2015</option>
                                  <option>2014</option>
                                  <option>2013</option>
                                  <option>2012</option>
                                  <option>2011</option>
                                  <option>2010</option>
                                  <option>2009</option>
                                  <option>2008</option>
                                  <option>2007</option>
                                  <option>2006</option>
                                  <option>2005</option>
                                  <option>2004</option>
                                  <option>2003</option>
                                  <option>2002</option>
                                  <option>2001</option>
                                  <option>2000</option>
                                  <option>1999</option>
                                  <option>1998</option>
                                  <option>1997</option>
                                  <option>1996</option>
                                  <option>1995</option>
                                  <option>1994</option>
                                  <option>1993</option>
                                  <option>1992</option>
                                  <option>1991</option>
                                  <option>1990</option>
                                  <option>1989</option>
                                  <option>1988</option>
                                  <option>1987</option>
                                  <option>1986</option>
                                  <option>1985</option>
                                  <option>1984</option>
                                  <option>1983</option>
                                  <option>1982</option>
                                  <option>1981</option>
                                  <option>1980</option>                                 
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label htmlFor="cname">Roll No/ Registration No: <span style={{ color:'red' }} >*</span> </label>
                              <input
                                type="text"
                                className="form-control"
                                id="rollNo"
                                onChange={onChange}
                                value={applicant.rollNo}
                                name="rollNo"
                                placeholder="Roll No."
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-6">
                              <label htmlFor="cname">Obtained Marks/ CGPA: <span style={{ color:'red' }} >*</span> </label>
                              <input
                                type="text"
                                className="form-control"
                                id="obtainedMarks"
                                onChange={onChange}
                                value={applicant.obtainedMarks}
                                name="obtainedMarks"
                                placeholder="Obtained Marks"
                              />
                            </div>
                            <div className="col-sm-6">
                              <label htmlFor="cname">Total Marks/ CGPA: <span style={{ color:'red' }} >*</span> </label>
                              <input
                                type="text"
                                className="form-control"
                                id="totalMarks"
                                onChange={onChange}
                                value={applicant.totalMarks}
                                name="totalMarks"
                                placeholder="totalMarks"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer justify-content-between">
                        <button
                          type="button"
                          className="btn btn-default"
                          data-dismiss="modal"
                        >
                          Close
                        </button>

                        <button
                          type="submit"
                          name="submit"
                          id="submit"
                          className="btn btn-primary"
                          disabled={
                            disableButton
                              ? "disabled"
                              : ""
                          }                        >
                          Add Qualification
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div
                className="btn-group float-right"
                role="group"
                aria-label="Buttons"
              >
                <button
                  type="button"
                  className="btn btn-warning btn-flat"
                  onClick={() => {
                    history("/1");
                  }}
                >
                  Previous
                </button>
                {secureLocalStorage.getItem("lockProfile") === "false" ? (
                  <button
                    type="button"
                    className="btn btn-warning btn-flat"
                    disabled={
                      applicantQualification !== undefined &&
                      applicantQualification.length > 0
                        ? ""
                        : "disabled"
                    }
                    onClick={nextStep}
                  >
                    Save & Next
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home2;
