import React from 'react'
import {Link, useLocation} from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";


const SideNav = () => {
  let location = useLocation();
  

  return (
    <div>
{/* <aside className="main-sidebar sidebar-dark-primary elevation-4"> */}
<aside className="main-sidebar main-sidebar-custom elevation-4 sidebar-light-warning">

  {/* Brand Logo */}
  <Link to="/1" className="brand-link">
    <img src="dist/img/uop-logo.png" alt="UoP Logo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} />
    <span className="brand-text font-weight-light">Home Page</span>
  </Link>
  {/* Sidebar */}
  <div className="sidebar">
    
    <nav className="mt-2">
      <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
       
        <li className="nav-item">
          <Link to="/1" className={`nav-link ${location.pathname ==="/1"?"active":""}`}>
            <i className="nav-icon fas fa-tachometer-alt" />
            <p>
              Personal Information
            </p>
          </Link>
         
        </li>
       
       {secureLocalStorage.getItem('step2')==="true" &&
        <li className="nav-item">
          <Link to="/2"  className={`nav-link ${location.pathname ==="/2"?"active":""}`} >
            <i className="nav-icon fas fa-edit" />
            <p>
              Academic Qualification
            </p>
          </Link>
        </li>}
        {secureLocalStorage.getItem('step3')==="true" &&
        <li className="nav-item">
          <Link to="/3"  className={`nav-link ${location.pathname ==="/3"?"active":""}`} >
            <i className="nav-icon fas fa-edit" />
            <p>
              Admission Test
            </p>
          </Link>
        </li>}
        {secureLocalStorage.getItem('step4')==="true" &&
        <li className="nav-item">
          <Link to="/4" className={`nav-link ${location.pathname ==="/4"?"active":""}`}>
            <i className="nav-icon fas fa-file" />
            <p>
              Upload Documents
            </p>
          </Link>
        
        </li>}
        {secureLocalStorage.getItem('step5')==="true" &&
        <li className="nav-item">
          <Link to="/5" className={`nav-link ${location.pathname ==="/5"?"active":""}`}>
            <i className="nav-icon fas fa-table" />
            <p>
              Apply for Admission
            </p>
          </Link>
        
        </li>}
       
     
     
    
       
      
  
 
      
      </ul>
    </nav>
  </div>
</aside>

      </div>
  )
}

export default SideNav
