import React, { useState, useEffect } from "react";
import ApplicantContext from "./ApplicantContext";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import  secureLocalStorage  from  "react-secure-storage";

const ApplicantState = (props) => {
  useEffect(() => {
    toastr.options = {
      closeButton: true,
      timeOut: 5000,
      progressBar: true,
      positionClass: "toast-top-center",
      fontSize: '16px'
    
    };
  }, []);

  const showSuccessMessage = (message,title) => {
    toastr.success(message, title);
  };

  const showErrorMessage = (message, title) => {
    toastr.error(message, title);
  };

  // Initialize the Applicant Profile to Empty array...
  const [applicant, setApplicant] = useState({
    name: "",
    fatherName: "",
    email: "",
    picture: "",
    dob: "",
    cnic: "",
    religion: "",
    gender: "",
    maritalStatus: "",
    hafizQuran: "",
    landline: "",
    contact: "",
    province: "",
    domicile: "",
    address: "",
    nationality: ""
  });
  const [applicantQualification, setApplicantQualification] = useState([
    {
      title: "",
      studyGroup: "",
      bprovince: "",
      board: "",
      gradingSystem: "",
      passingYear: "",
      rollno: "",
      obtainedMarks: "",
      totalMarks: "",
    },
  ]);
  const [applicantTest, setApplicantTest] = useState([
    {
      testId: "",
      title: "",
      passingDate: "",
      expiryDate: "",
      rollNo: "",
      obtainedMarks: "",
      totalMarks: "",
    },
  ]);
  const [applicantApplications, setApplicantApplications] = useState([]);
  const getApplicantQualification = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}api/applicantRoutes/getApplicantQualification`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": secureLocalStorage.getItem("token"),
        },
      }
    );

    const json = await response.json();
    if (json.success) 
    setApplicantQualification(json.applicantQualifications);
    else
    setApplicantQualification({
      title: "",
      studyGroup: "",
      bprovince: "",
      board: "",
      session: "",
      passingYear: "",
      rollno: "",
      obtainedMarks: "",
      totalMarks: "",
    });
  };

  const getApplicantTest = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}api/applicantRoutes/getApplicantTest`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": secureLocalStorage.getItem("token"),
        },
      }
    );

    const json = await response.json();
    if (json.success) 
    setApplicantTest(json.test_data);
    else
    setApplicantTest( {
      testId: "",
      title: "",
      passingDate: "",
      expiryDate: "",
      rollNo: "",
      obtainedMarks: "",
      totalMarks: "",
    });
  };

  const [provinces, setProvinces] = useState([]);

  const getProvinces = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}api/lookupRoutes/getProvinces`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": secureLocalStorage.getItem("token"),
        },
      }
    );

    const json = await response.json();
    if (json.success) setProvinces(json.province_data);
  };

  const getApplications = async () => {
    // Fetch applications of an applicant

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}api/applicationRoutes/getApplications`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": secureLocalStorage.getItem("token"),
        },
      }
    );

    // now fetching below...
    const json = await response.json();
    // showing on console......
    // The notes returned from DB is assigned to "notes" variable..
    if (json.success) {
      setApplicantApplications(json.application_data);
    }
  };

  // Delete specific qualification......
  const deleteQualification = async (id, setUpdateCounter, updateCounter) => {
    // API Call here....
    const did = {id};
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}api/applicantRoutes/deleteQualification`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": secureLocalStorage.getItem("token"),
        },
        body: JSON.stringify(did),
      }
    );

    const json = await response.json();
    if (json.success) {
      
      setUpdateCounter(updateCounter + 1);
      showErrorMessage("Qualification Deleted Sucessfully !","SUCCESS");

    }
  };
  const deleteTest = async (id, setUpdateCounter, updateCounter) => {
    // API Call here....
    const did = {id};
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}api/applicantRoutes/deleteTest`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": secureLocalStorage.getItem("token"),
        },
        body: JSON.stringify(did),
      }
    );

    const json = await response.json();
    if (json.success) {
      
      setUpdateCounter(updateCounter + 1);
      showErrorMessage("Test Entry Deleted Sucessfully !","SUCCESS");

    }
  };

  const [applicantDocuments, setApplicantDocuments] = useState({
    picture: "",
    dmc: "",
    testDoc: ""
  });
  const getApplicantDocuments = async () => {
    // Fetch Applicant Profile ..

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}api/applicantRoutes/getApplicantDocuments`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": secureLocalStorage.getItem("token"),
        },
      }
    );

    // now fetching below...
    const json = await response.json();
    // showing on console......
    // The notes returned from DB is assigned to "notes" variable..
    if (json.success) setApplicantDocuments(json.applicantDocuments);
  };

  const getApplicantProfile = async () => {
    // Fetch Applicant Profile ..

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}api/applicantRoutes/getApplicantProfile`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": secureLocalStorage.getItem("token"),
        },
      }
    );

    // now fetching below...
    const json = await response.json();
    if (json.success) 
    {setApplicant(json.profile_data);}
    else{
      setApplicant({
        name: "",
        fatherName: "",
        picture: "",
        dob: "",
        cnic: "",
        religion: "",
        gender: "",
        maritalStatus: "",
        hafizQuran:"",
        landline: "",
        contact: "",
        province: "",
        domicile: "",
        address: "",
        nationality: ""

      });
    }
  };
  // const updateApplicantDetails = async () => {

  // }

  const [applicantStatus, setAppplicantStatus] = useState({});

  const authenticateApplicant = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}api/userRoutes/authenticateApplicant`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": secureLocalStorage.getItem("token"),
        },
      }
    );
    
    if (!response.ok) {
      // handle non-200 response status codes
      if (response.status === 401) {
        // handle unauthorized error
        console.log("Unauthorized error");
        return false;
      } else {
        // handle other errors
        console.log(`Error: ${response.status} - ${response.statusText}`);
        return false;
      }
    } else {
      const json = await response.json();
      if (json.success) {
        setAppplicantStatus(json.user);
        return true;
      } else {
        return false;
      }
    }
  };
  



  return (
    <ApplicantContext.Provider
      value={{
        applicant,
        setApplicant,
        getApplicantProfile,
        applicantQualification,
        setApplicantQualification,
        getApplicantQualification,
        applicantTest,
        getApplicantTest,
        deleteTest,
        applicantDocuments,
        setApplicantDocuments,
        getApplicantDocuments,
        deleteQualification,
        getApplications,
        applicantApplications,
        getProvinces,
        provinces,
        authenticateApplicant,
        applicantStatus,
        showSuccessMessage,
        showErrorMessage,
      }}
    >
      {props.children}
    </ApplicantContext.Provider>
  );
};

export default ApplicantState;
