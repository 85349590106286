import { useState, useEffect, useContext, Suspense, lazy } from "react";
import { useNavigate } from "react-router-dom";
import applicantContext from "../context/ApplicantContext";
//import PreviewPDF from "./PreviewPDF";
import  secureLocalStorage  from  "react-secure-storage";
// import PreviewTestResult from "./PreviewTestResult";
//import UploadDocuments from "./UploadDocuments";
// const PreviewPDF = lazy(() => import('./PreviewPDF'));
const UploadDocuments = lazy(() => import('./UploadDocuments'));


const Home4 = (props) => {
  const context = useContext(applicantContext);
  const { applicantDocuments, getApplicantDocuments, authenticateApplicant } = context;
  const [mandatoryNotUploadedCount, setMandatoryNotUploadedCount] = useState(0);


  const [updateCounter, setUpdateCounter] = useState(0);
  const [fileToShow, setFileToShow] = useState('');
  const [requiredDocuments, setRequiredDocuments] = useState([
    {
      documentTitle: 'Profile Picture',
      documentType: 'image',
      documentStatus: 'mandatory'
    }
  ]);
  let history = useNavigate();
  const nextStep = async () =>{
    // updating the step2 true status....
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_ADDRESS}api/userRoutes/updateField`,
      {
        method: "POST", headers: { "Content-Type": "application/json","auth-token": secureLocalStorage.getItem("token") },
        body: JSON.stringify({ "fieldName": "step5", "fieldValue": "true"})
      });
      const str = await response.json();
      if(str.success)
    secureLocalStorage.setItem("step5",'true');
                                  history("/5");
                                }
  useEffect(() => {
    props.setProgress(25);
    authenticateApplicant(secureLocalStorage.getItem("token"))
      .then((isAuthenticated) => {
        if (isAuthenticated) {
          props.setProgress(50);

         getApplicantDocuments();
          props.setProgress(100);

        } else {
          secureLocalStorage.clear();
          history("/login");
        }
      });
    
  }, [updateCounter]);
  useEffect(() => {
    // Calculate the count of mandatory documents not uploaded
    let count = 0;
    requiredDocuments.forEach(pro => {
      const uploadedDocument = Array.isArray(applicantDocuments) ? applicantDocuments.find(appDoc => appDoc.documentTitle === pro.documentTitle): null;
      const isUploaded = !!uploadedDocument;
      const isMandatoryNotUploaded = pro.documentStatus === 'mandatory' && !isUploaded;
      if (isMandatoryNotUploaded) {
        count++;
      }
    });
    setMandatoryNotUploadedCount(count);
  }, [requiredDocuments, applicantDocuments]);


  return (
    <>
      <div className="content-wrapper" style={{ minHeight: "500px" }}>
        <div className="content-header">
          {/* <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Applicant Portal</h1>
              </div>
            </div>
          </div> */}
        </div>

        <section className="content">
          <div
            className="card card-primary card-outline"
            style={{ minHeight: "466px" }}
          >
             <div className="card-header">
              <h5 className="card-title" style={{fontWeight:'bold'}}>Step 4 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg> Upload Documents</h5>
            </div>

            <div className="card-body pb-0">
            <h4>Note:</h4><p style={{color:'red', fontWeight:'bold'}}>This is a Mandatory Step. Please upload the following documents:</p>

              
              <div className="card-body table-responsive p-0 table-bordered my-2">
                <table className="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Document Type</th>
                      <th>Status</th>

                    </tr>
                  </thead>
                  <tbody>
                  {/* {requiredDocuments && requiredDocuments.map((pro, index) => (
                    <tr>
                      <td>{index+1}</td>
                      <td>{pro.documentTitle}</td>
                      <td>
                        {/* {applicantDocuments.picture !== "" ? (
                          <span>
                            <img
                              className=" mx-3"
                              src={`${process.env.REACT_APP_BACKEND_ADDRESS}static/${pro.documentFileName}`}
                              style={{
                                width: "100px",
                                height: "130px",
                                border: "1px solid gray",
                              }}
                              alt="Profile Picture"
                            />
                          </span>
                        ) : (<p style={{color:'red', fontWeight:'bold'}}>
                          You have not yet uploaded your profile Picture
                          </p>)} 
                          Not Uploaded
                      </td>
                   
                    </tr>

                  ))}   */}
                  {requiredDocuments && requiredDocuments.map((pro, index) => {
  const uploadedDocument = Array.isArray(applicantDocuments) ? applicantDocuments.find(appDoc => appDoc.documentTitle === pro.documentTitle): null;
  const isUploaded = !!uploadedDocument;
  const isMandatoryNotUploaded = pro.documentStatus === 'mandatory' && !isUploaded;
  
  return (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>{pro.documentTitle}</td>
      <td>
        {isUploaded ? (
          <div>
            <span className='col-style-xs mx-3' dangerouslySetInnerHTML={{ __html: "&#9989;"  }} />
            <a href={`${process.env.REACT_APP_BACKEND_ADDRESS}static/${uploadedDocument.documentFileName}`} target="_blank" rel="noopener noreferrer">
          [Click here to View]
        </a>          
        </div>
        ) : (<>
          <span className='col-style-xs mx-3' dangerouslySetInnerHTML={{ __html: "&#10060;"  }} />
          {isMandatoryNotUploaded && <span>It must be Provided</span>}
          </>
          )}
      </td>
    </tr>
  );
})}

                   
                  </tbody>
                </table>
              </div>

              <center>
              {secureLocalStorage.getItem("lockProfile") === "false"?(                <button
                  type="button"
                  className="btn btn-success"
                  data-toggle="modal"
                  data-target="#modal-lg"
                >
                  Click here to Upload
                </button>):''}
              </center>

              <Suspense fallback={<div>Loading...</div>}>
              <UploadDocuments
                setUpdateCounter={setUpdateCounter}
                updateCounter={updateCounter}
                requiredDocuments={requiredDocuments}
              />
              {/* <PreviewPDF doc={applicantDocuments.dmc}  />
              <PreviewTestResult testDoc={applicantDocuments.testDoc} /> */}

                </Suspense>

            </div>
            <div className="card-footer">
              <div
                className="btn-group float-right"
                role="group"
                aria-label="Buttons"
              >
                <button
                  type="button"
                  onClick={() => {
                    history("/3");
                  }}
                  className="btn btn-warning btn-flat"
                >
                  Previous
                </button>
                <button
  disabled={mandatoryNotUploadedCount > 0}
  type="button"
  onClick={nextStep}
  className="btn btn-warning btn-flat"
>
  Next
</button>

              </div>

              <div></div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home4;
